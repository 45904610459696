import React, { Component } from 'react';
import { Container, Row, Col, Card, Button, Alert, Form, Badge, ButtonGroup } from 'react-bootstrap';
import { PiShippingContainer } from "react-icons/pi";
import { MdOutlineExpandMore } from "react-icons/md";
import { AgGridReact } from 'ag-grid-react';
import { GoX, GoInfo } from "react-icons/go";
import Navigation from 'context/nav';
import Utils from 'context/utils';
import CreateSessionModal from './create';

export default class SessionListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: true,
      sessions: null,
      lastSessionId: null,
      createSessionModelShow: false,
      progress: false,
      error: false,
      message: null,
    };
  }

  componentDidMount() {
    this.loadSessions(false);
  }

  updateProgress = (progress, error, message) => {
    this.setState({
      progress: progress,
      error: error,
      message: message
    });
  }

  loadSessions(more) {
    // Render progress
    this.updateProgress(true, false, "Loading sessions...");

    // Fetch the list
    const body = {
      engine: "workflow_blobhub",
      command: "list_sessions"
    };
    // Page results from where we left off the last time
    if ( more ) {
      body["start_session_id"] = this.state.lastSessionId;
    }
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        // Clear progress
        this.updateProgress(false, false, null);

        const sessions = response.data.sessions;
        const lastSessionId = response.data.last_session_id;

        // Merge results with previously loaded ones in case of paging
        if ( more ) {
          sessions.push(...this.state.sessions);
        }

        // Update component state
        this.setState({
          sessions: sessions,
          lastSessionId: lastSessionId
        });
      }, error => {
        // Display failure message
        this.updateProgress(false, true, "Failed to fetch the list of sessions: " + error.message);
      });
  }

  createSession() {
    // Render progress
    this.updateProgress(true, false, "Creating a new session...");

    const body = {
      engine: "workflow_blobhub",
      command: "create_session"
    };
    this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body)
      .then( response => {
        const sessionId = response.data["session"]["id"];

        // Navigate to newly created session
        this.proceedToSession(sessionId);
      }, error => {
        // Render error state
        this.updateProgress(false, true, "Failed to create session: " + error.message);
      });
  }

  proceedToSession(sessionId) {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    this.props.history.push(Navigation.workflowPath(orgId, blobId, "debugger", sessionId));
  }

  render() {
    const columns = [
      {
        "field": "id",
        "headerName": "Session ID",
        "filter": true,
        "flex": 10,
        "sortable": false,
        cellRenderer: params => {
          return (
            <a href="#" onClick={(event) => {
              event.preventDefault();
              this.proceedToSession(params.value);
            }}>{params.value}</a>
          );
        }
      },
      {
        "field": "created_at",
        "headerName": "Created At (UTC)",
        "filter": false,
        "flex": 9,
        "sortable": true,
        "sort": "desc",
        cellRenderer: params => {
          return ( <> { Utils.formatDateTime(params.value) } </> );
        }
      },
      {
        "field": "status",
        "headerName": "Status",
        "filter": true,
        "flex": 4,
        "sortable": true,
        cellRenderer: params => {
          return ( <Badge bg="info" className="title-tag-badge">{params.value}</Badge> );
        }
      }
    ];

    return (
      <>
        {( this.state.progress || this.state.error ) &&
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Alert variant={this.state.progress ? "primary" : "warning"}>
                    {this.state.message}
                  </Alert>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        }
        <Container>
          <Row>
            <Col sm={this.state.details ? 8 : 12}>
              <Card>
                <Card.Header>
                  Sessions
                  <span className="float-right">
                    {this.state.sessions &&
                      <>
                        Total fetched: <strong>{this.state.sessions.length}</strong>
                        {this.state.lastSessionId &&
                          <> | More available</>
                        }
                      </>
                    }
                    {!this.state.details &&
                      <ButtonGroup size="sm" className="header-options">
                        &nbsp;&nbsp;
                        <Button variant="light" onClick={() => { this.setState({ details: true }) }}><GoInfo/></Button>
                      </ButtonGroup>
                    }
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div
                      className="ag-theme-quartz"
                    >
                      <AgGridReact
                        rowData={this.state.sessions}
                        columnDefs={columns}
                        domLayout='autoHeight'
                        pagination={false}
                      />
                    </div>
                  </Card.Text>
                  {this.state.lastSessionId && !this.state.progress &&
                    <Card.Text>
                      <Button variant="light" size="sm" onClick={() => {
                        this.loadSessions(true);
                      }}><MdOutlineExpandMore /> Load More Results</Button>
                    </Card.Text>
                  }
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className={this.state.details ? "" : "hidden"}>
              <Card>
                <Card.Header>
                  Actions
                  <span className="float-right">
                    <ButtonGroup size="sm" className="header-options">
                      <Button variant="light" onClick={() => { this.setState({ details: false }) }}><GoX/></Button>
                    </ButtonGroup>
                  </span>
                </Card.Header>
                <Card.Body>
                  {!this.state.progress ?
                    <>
                      <Card.Text>
                        <Button variant="success" size="sm" onClick={() => {
                          this.setState({ createSessionModelShow: true });
                        }}><PiShippingContainer /> New Session</Button>
                      </Card.Text>
                      <Card.Text>
                        Session entity exists within the framework to provide a container for workflow executions.
                        Executions share its state within the session allowing for it to exist outside of execution lifespan.
                      </Card.Text>
                    </> : <>
                      <Card.Text>
                        Not available while loading is in progress.
                      </Card.Text>
                    </>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <CreateSessionModal
          show={this.state.createSessionModelShow}
          onHide={() => this.setState({ createSessionModelShow: false })}
          handleCreate={() => this.createSession()}
        />
      </>
    );
  }
}
