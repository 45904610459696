import React, { Component } from 'react';
import EditorShell from '../editor/index.js';
import DebuggerShell from '../debugger/index.js';
import PlaygroundShell from '../playground/index.js';

export default class WorkflowsShell extends Component {
  render() {
    const section = this.props.match.params.section || "default";
    return (
      <>
        <div className={( "default" === section ) ? "" : "hidden"}>
          <EditorShell
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            match={this.props.match}
            history={this.props.history}
            location={this.props.location}
            shown={"default" === section}
          />
        </div>
        <div className={( "debugger" === section ) ? "" : "hidden"}>
          <DebuggerShell
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            match={this.props.match}
            history={this.props.history}
            location={this.props.location}
          />
        </div>
        <div className={( "playground" === section ) ? "" : "hidden"}>
          <PlaygroundShell
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            match={this.props.match}
            history={this.props.history}
            location={this.props.location}
            shown={"playground" === section}
          />
        </div>
      </>
    );
  }
}
