import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

export default class DetailsPane extends Component {
  render() {
    return (
      <>
        <Card.Text as="h6">General</Card.Text>
        <hr />
      </>
    );
  }
}
