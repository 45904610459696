export default class Connections {
  static checkConnectionPortsCompatibility(connection) {
    if ( connection.sourceHandle === "flow_output" && connection.targetHandle === "flow_input" ) {
      return true;
    }

    if ( connection.sourceHandle === "data_input" && connection.targetHandle === "data_reference" ) {
      return true;
    }

    if ( connection.sourceHandle === "data_output" && connection.targetHandle === "data_reference" ) {
      return true;
    }

    if ( connection.sourceHandle === "provider_input" && connection.targetHandle === "provider_reference" ) {
      return true;
    }

    return false;
  }

  static isFlowConnection(connection) {
    return ( connection.sourceHandle === "flow_output" && connection.targetHandle === "flow_input" );
  }
}
