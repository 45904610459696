import React, { Component } from 'react';
import { Container, Row, Col, Breadcrumb, Badge } from 'react-bootstrap';
import Navigation from 'context/nav';
import SessionListView from './sessions/index';
import SessionView from './session/index';
import ExecutionView from './execution/index';

export default class DebuggerShell extends Component {
  /**
   * The following routes are supported here:
   *
   * - /:orgId/:blobId/debugger
   *   Entry point, renders the list of sessions
   *
   * - /:orgId/:blobId/debugger/:sessionId
   *   Renders session details, list of executions
   *
   * - /:orgId/:blobId/debugger/:sessionId/:executionId
   *   Execution tracking
   */

  onClick(event, path) {
    event.preventDefault();
    this.props.history.push(path);
  }

  render() {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    const section = this.props.match.params.section;
    const sessionId = this.props.match.params.sectionId;
    const executionId = this.props.match.params.subSectionId;

    const rootLink = ( sessionId ? true : false );
    const sessionLink = ( executionId ? true : false );
    const executionLink = false;

    return (
      <>
        <Container className="blob-header-row content-row">
          <Row>
            <Col>
              <Breadcrumb style={{
                "margin-bottom": "-4px"
              }}>
                <Breadcrumb.Item
                  onClick={(event) => {
                    this.onClick(event, Navigation.workflowPath(orgId, blobId, "debugger"))
                  }}
                  active={!rootLink}>
                  Root
                </Breadcrumb.Item>
                {sessionId &&
                  <>
                    <Breadcrumb.Item
                      onClick={(event) => {
                        this.onClick(event, Navigation.workflowPath(orgId, blobId, "debugger", sessionId))
                      }}
                      active={!sessionLink}>
                      Session&nbsp;&nbsp;
                      <Badge bg="info" className="title-tag-badge">{sessionId}</Badge>
                    </Breadcrumb.Item>

                    {executionId &&
                      <Breadcrumb.Item
                        onClick={(event) => {
                          this.onClick(event, Navigation.workflowPath(orgId, blobId, "debugger", sessionId, executionId))
                        }}
                        active={!executionLink}>
                        Execution&nbsp;&nbsp;
                        <Badge bg="info" className="title-tag-badge">{executionId}</Badge>
                      </Breadcrumb.Item>
                    }
                  </>
                }
              </Breadcrumb>
            </Col>
          </Row>
        </Container>

        {!sessionId &&
          <SessionListView
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            match={this.props.match}
            history={this.props.history}
            location={this.props.location}
          />
        }
        {( ( "debugger" === section ) && sessionId && !executionId ) &&
          <SessionView
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            match={this.props.match}
            history={this.props.history}
            location={this.props.location}
          />
        }
        {( ( "debugger" === section ) && sessionId && executionId ) &&
          <ExecutionView
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            match={this.props.match}
            history={this.props.history}
            location={this.props.location}
          />
        }
      </>
    );
  }
}
