import React from 'react';
import { Card, ListGroup, ListGroupItem, Badge } from 'react-bootstrap';

import BaseNlpFormat from './base';

export default class NlpFormat extends BaseNlpFormat {
  /**
   * BaseNlpFormat
   */

  getKeyField() {
    return "idx";
  }

  getColumns() {
    return [{
        "field": "idx",
        "headerName": "ID",
        "filter": true,
        "flex": 1
      }, {
        "field": "passage.text",
        "headerName": "Passage Text",
        "filter": true,
        "flex": 5
      }];
  }

  getDefaultSorted() {
    return [{
      dataField: "idx",
      order: "asc"
    }];
  }

  getRowExpansionOptions() {
    return {
      renderer: this.expandedRowRender,
      onlyOneExpanding: true
    };
  }

  /**
   * Row Expansion
   */

  expandedRowRender = (row) => {
    var questions = [];
    for (let question of row.passage.questions) {
      var answers = [];
      for (let answer of question.answers) {
        var answerText = answer.text;
        if ( answerText.length > 128 ) {
          answerText = answerText.substring(0, 128) + "...";
        }
        answers.push(
          <>
            <Badge variant={answer.label === 1 ? "success" : "warning"}>{answerText}</Badge> &nbsp;
          </>
        );
      }
      questions.push(
        <ListGroupItem>
          {question.question} <br/>
          {answers}
        </ListGroupItem>
      );
    }

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Text>
              <strong>Passage Text</strong>
            </Card.Text>
            <Card.Text>
              <Card>
                <Card.Body>
                  {row.passage.text}
                </Card.Body>
              </Card>
            </Card.Text>
            <Card.Text>
              <strong>Questions</strong>
            </Card.Text>
            <Card.Text>
              <Card>
                <ListGroup className="list-group-flush">
                  {questions}
                </ListGroup>
              </Card>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
