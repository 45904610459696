import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tagsinput/react-tagsinput.css';
import ReactGA from 'react-ga';
import { GoogleOAuthProvider } from "@react-oauth/google"
import App from './pages/App/index.js';
import Config from "context/config";
import * as serviceWorker from './serviceWorker';
import './index.css';

// Initialize Google Analytics
ReactGA.initialize(Config.analyticsGoogleId());

// Initialize application
ReactDOM.createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId={Config.authGoogleClientId()}>
    <App />
  </GoogleOAuthProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
