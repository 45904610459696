import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { GoHistory, GoGear, GoWorkflow, GoPlay } from 'react-icons/go';
import { VscDebugLineByLine } from "react-icons/vsc";

export default class BlobTabs extends Component {
  handleTabSelect(tabSection) {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;

    // Redirect to corresponding page
    var pagePath = `\/${orgId}\/${blobId}`;
    if ( "default" == tabSection && this.props.forcedRevision ) {
      pagePath += `\/revisions\/${this.props.revision.id}`;
    } else if ( "default" != tabSection ) {
      pagePath += `\/${tabSection}`;
    }
    this.props.history.push(pagePath);
  }

  render() {
    return (
      <>
        <Container className="blob-header-row main-tabs">
          <Tabs defaultActiveKey="default" activeKey={this.props.section} onSelect={(key) => this.handleTabSelect(key)}>
            {this.props.revision && "draft" == this.props.revision.phase &&
              <Tab eventKey="default" title={<><GoWorkflow/> Editor</>}></Tab>
            }
            {this.props.revision && "commit" == this.props.revision.phase &&
              <Tab eventKey="default" title={<><GoWorkflow/> Editor</>}></Tab>
            }
            {this.props.revision && "snapshot" == this.props.revision.phase &&
              <Tab eventKey="default" title={<><GoWorkflow/> Editor</>}></Tab>
            }
            {!this.props.revision &&
              <Tab eventKey="default" title={<><GoWorkflow/> Loading...</>}></Tab>
            }
            <Tab eventKey="debugger" title={<><VscDebugLineByLine/> Debugger</>}></Tab>
            <Tab eventKey="playground" title={<><GoPlay/> Playground</>}></Tab>
            <Tab eventKey="revisions" title={<><GoHistory/> Revisions</>}></Tab>
            {(this.props.blob && this.props.app.api.isBlobSettingsAccessible(this.props.blob)) &&
              <Tab eventKey="settings" title={<><GoGear/> Settings</>}></Tab>
            }
          </Tabs>
        </Container>
      </>
    )
  }
}
