import React, { Component } from 'react';
import { Card, Form, Badge, Button, ButtonGroup } from 'react-bootstrap';
import { applyEdgeChanges } from '@xyflow/react';
import { GoX, GoPlus } from "react-icons/go";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Converter from "../converter.js"
import Utils from "context/utils";

export default class DetailsPane extends Component {
  render() {
    const edge = this.props.shell.details.data;

    let nodesIndex = Converter.nodesToIndex(this.props.shell.nodes);

    let customOption = false;
    let routeOptions = [];
    let selectedRouteOption = null;
    const sourceNode = nodesIndex[edge.source];
    const sourceCategory = edge.sourceHandle;
    for ( const portManifest of sourceNode.data.manifest["ports"] ) {
      // Filter out other port categories
      if ( sourceCategory != portManifest["category"] ) {
        continue;
      }

      // Register route option
      const routeOption = {
        value: portManifest.route,
        label: portManifest.route
      };
      routeOptions.push(routeOption);

      // Apply selection
      if ( edge.data.route == portManifest.route ) {
        selectedRouteOption = routeOption;
      }

      // Allow custom options
      if ( portManifest["custom"] ) {
        customOption = true;
      }
    }

    // Selected route was not found in manifest slots
    if ( !selectedRouteOption ) {
      selectedRouteOption = {
        value: edge.data.route,
        label: edge.data.route
      }
      routeOptions.push(selectedRouteOption);
    }

    return (
      <>
        <Card.Text as="h6">General</Card.Text>
        <hr />
        <Card.Text>Basics</Card.Text>
        <Card.Text>
          <table className="properties">
            <tr>
              <td className="prop-name-column">ID</td>
              <td className="prop-value-column"><code>{edge.id}</code></td>
            </tr>
          </table>
        </Card.Text>
        <Card.Text>
          Route
        </Card.Text>
        <Card.Text>
          {customOption ?
            <>
              <Creatable
                options={routeOptions}
                value={selectedRouteOption}
                onChange={(option) => {
                  // Update the edge
                  edge.label = option.label;
                  edge.data.route = option.label;

                  // Make route update go through all the way to the UX
                  this.forceReactFlowEdgesReRender();
                }}
              />

              <Form.Text muted>
                Select one of pre-defined routes or create custom one.
              </Form.Text>
            </>
          :
            <Select
              options={routeOptions}
              value={selectedRouteOption}
              onChange={(option) => {
                // Update the edge
                edge.label = option.label;
                edge.data.route = option.label;

                // Make route update go through all the way to the UX
                this.forceReactFlowEdgesReRender();
              }}
            />
           }
        </Card.Text>
      </>
    );
  }

  /**
   * THis is a total hack... By default ReactFlow would not update edge label until user touches the control.
   * In here we force the update by inserting new edge right behind currently selected one and later on removing it.
   */
  forceReactFlowEdgesReRender() {
    const edge = this.props.shell.details.data;

    // Create new fake edge
    let fakeEdge = Converter.deepCopy(edge);
    fakeEdge.id = Utils.generateUniqueId();
    fakeEdge.selected = false;
    delete fakeEdge.label;

    // Add it to the list - this causes the control to re-render
    let newEdges = applyEdgeChanges([
      { type: "add", item: fakeEdge }
    ], this.props.shell.edges);
    this.props.shell.workflowInterface.setEdges(newEdges, true);

    // Remove fake edge
    const timerId = setTimeout(() => {
      let newEdges = applyEdgeChanges([
        { type: "remove", id: fakeEdge.id }
      ], this.props.shell.edges);
      this.props.shell.workflowInterface.setEdges(newEdges, true);

      clearTimeout(timerId);
    }, 10);
  }
}
