import { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default class TooltipHelper extends Component {
  render() {
    return (
      <OverlayTrigger placement="bottom"
        overlay={
          <Tooltip>{this.props.text}</Tooltip>
        }
      >
        {this.props.children}
      </OverlayTrigger>
    );
  }
}
