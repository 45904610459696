import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import Octicon, {Person, Info} from '@github/octicons-react';
import Utils from "context/utils";

export default class RevisionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      author: null
    };
  }

  componentDidMount() {
    this.loadAuthor();
  }

  loadAuthor() {
    // Load author
    this.props.app.api.usersIdGet(this.props.revision.author_id)
      .then(response => {
        this.setState({
          author: response.data.user
        });
      }, error => {});
  }

  handleShowDetails = () => {
    this.props.shell.shellInterface.toggleDetails();
  }

  render() {
    var revision = this.props.revision;

    return (
      <>
        <Octicon icon={Person}/>&nbsp;
        <strong>
          {this.state.author
            ? <a href={`/p/${this.state.author.alias}`}>{this.state.author.alias}</a>
            : ""}
        </strong> | {revision.comment}
        <span className="float-right">
          {Utils.formatTimeAgo(revision.created_at)} | <strong>{revision.phase}</strong>

          {this.props.shell && !this.props.shell.details.visible &&
            <>
              &nbsp;&nbsp;&nbsp;
              <ButtonGroup size="sm" className="header-options">
                <Button variant="light" onClick={this.handleShowDetails}><Octicon icon={Info}/></Button>
              </ButtonGroup>
            </>
          }
        </span>
      </>
    );
  }
}
