import React, { Component } from 'react';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import { GoX } from "react-icons/go";
import 'components/graphs/generic/details/index.css';
import ProcessorDetails from './processor.js';
import ConnectionDetails from './connection.js';
import "./index.css";

export default class DetailsPane extends Component {
  /**
   * UI Handlers
   */

  handleClose = () => {
    this.props.shell.shellInterface.toggleDetails();
  }

  /**
   * Rendering Section
   */

  renderContent() {
    if ( "processor" == this.props.shell.details.view ) {
      return (
        <ProcessorDetails shell={this.props.shell} />
      );
    } else if ( "connection" == this.props.shell.details.view ) {
      return (
        <ConnectionDetails shell={this.props.shell} />
      );
    } else {
      return (
        <Card.Text>
          Select a single processor or connection in the workflow editor to see its properties here.
        </Card.Text>
      );
    }
  }

  render() {
    return (
      <Card>
        <Card.Body className="workflow-library-components">
          <span className="workflow-controls-pane-right">
            <Button variant="light" onClick={() => {
              this.props.shell.shellInterface.updatePane("details", false);
            }}><GoX/></Button>
          </span>

          {this.renderContent()}
        </Card.Body>
      </Card>
    );
  }
}
