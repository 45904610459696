import { format } from "timeago.js";

export default class Utils {
  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static formatTimeAgo(time) {
    return format(time + " UTC");
  }

  static formatDateTime(time) {
    return new Date(time).toLocaleDateString(
      'en-us', {
        weekday: "long", year: "numeric", month: "long", day: "numeric",
        hour: "numeric", minute: "numeric", second: "numeric"
      }
    );
  }

  static formatTime(time) {
    return new Date(time).toLocaleTimeString(
      'en-us', {
        hour: "numeric", minute: "numeric", second: "numeric"
      }
    );
  }

  static deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static generateUniqueId() {
    return crypto.randomUUID();
  }
}
