import Utils from "context/utils";

export default class Converter {
  constructor(shellInterface) {
    this.shellInterface = shellInterface;
  }

  /**
   * Post-processing
   */

  static parseManifest(manifest) {
    manifest["components_index"] = {};
    for ( const componentGroup of manifest["component_groups"] ) {
      for ( const componentManifest of componentGroup["component_manifests"] ) {
        // Processors index
        manifest["components_index"][componentManifest["type"]] = componentManifest;

        // Ports index
        componentManifest["ports_index"] = {};
        for ( const port of componentManifest["ports"] ) {
          const key = port["category"] + "-" + ( port["route"] || "default" );
          componentManifest["ports_index"][key] = port;
        }
      }
    }
  }

  /**
   * Conversion
   */

  blobhubDefinitionToReactFlow(definition, manifest) {
    let nodes = [];
    let edges = [];

    // Create nodes
    for ( const component of definition["components"] ) {
      // Create new node
      let node = {
        type: component["type"],
        id: component["id"],
        position: component["position"],
        data: {
          component: Utils.deepCopy(component),
          manifest: manifest["components_index"][component["type"]],
          shellInterface: this.shellInterface
        }
      };
      nodes.push(node);

      // Stub manifest when processor of a specified type is no longer supported
      if ( !node.data.manifest ) {
        node.data.processor.type = "no.longer.exists";
        node.data.manifest = {
          ports: []
        };
      }
    }

    return [ nodes, edges ];
  }

  reactFlowToBlobhubDefinition(nodes, edges) {
    let definition = {
      "type": "playground_definition",
      "version": "1.0.1",
      "components": [],
      "connections": [],
    };

    // Create components
    for ( const node of nodes ) {
      let component = Utils.deepCopy(node.data.component);
      component.position = Utils.deepCopy(node.position);
      definition["components"].push(component);
    }

    return definition;
  }

  /**
   * Creation
   */

  createNewNode(componentDefinition, position, nodes) {
    // Create component based on definition
    const component = {
      id: Utils.generateUniqueId(),
      category: componentDefinition["category"],
      type: componentDefinition["type"],
      name: componentDefinition["label"],
      ports: []
    };

    // Initialize data input port values
    for ( const portManifest of componentDefinition["ports"] ) {
      if ( "data_input" == portManifest["category"] ) {
        // Create port definition
        const port = {
          "category": "data_input",
          "route": portManifest["route"]
        };

        // Populate with initial value
        Converter.initPortValue(port, portManifest);

        // Register port on the component
        component.ports.push(port);
      }
    }

    // Create React Flow node
    const node = {
      type: component.type,
      id: component.id,
      position: position,
      data: {
        component: component,
        manifest: componentDefinition,
        shellInterface: this.shellInterface
      }
    };

    return node;
  }

  /**
   * Modification
   */

  doReactFlowChangesImpactDefinition(changes) {
    const impactingChanges = new Set([
      // Common changes
      "add", "remove",
      // Node changes
      "replace", "position",
      // Edge changes
      "reset"
    ]);

    for ( const change of changes ) {
      if ( impactingChanges.has(change.type) ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Helpers
   */

  static initPortValue(port, portManifest) {
    port["value"] = {
      "type": portManifest["value"]["type"]
    };
    if ( "integer" == port["value"]["type"] ) {
      port["value"]["integer"] = portManifest["value"]["default"] || 0;
    } else if ( "component_alias" == port["value"]["type"] ) {
      port["value"]["alias"] = "";
    } else if ( "workflow_definition_id" == port["value"]["type"] ) {
      port["value"]["id"] = "";
    }
  }

  static getValueData(value) {
    const type = value["type"];
    let data = undefined;
    if ( "text" == type ) {
      data = value["text"];
    } else if ( "integer" == type ) {
      data = value["integer"];
    } else if ( "float" == type ) {
      data = value["float"];
    } else if ( "message" == type ) {
      data = value["message"];
    } else if ( "messages" == type ) {
      data = value["messages"];
    } else if ( "component_alias" == type ) {
      data = value["alias"];
    } else if ( "workflow_definition_id" == type ) {
      data = value["id"];
    }
    return data;
  }

  static getPortValueData(port) {
    return Converter.getValueData(port["value"]);
  }

  static findComponentPort(component, route) {
    for ( const port of component["ports"] || [] ) {
      if ( route == port["route"] ) {
        return port;
      }
    }
    return null;
  }

  static getComponentPortValueData(component, route) {
    const port = Converter.findComponentPort(component, route);
    if ( null == port ) {
      return null;
    }
    return Converter.getPortValueData(port);
  }
}
