import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import axios from 'axios';
import Config from "context/config";

import RevisionDetailsView from 'components/controls/RevisionDetailsView/index';
import InfoTooltip from 'components/controls/InfoTooltip/index'

import DownloaderView from './downloader';
import './index.css';

import MultiRCNlpFormat from './formats/multirc';
import RecordNlpFormat from './formats/record';
import SchemaNlpFormat from './formats/schema';

export default class DatasetPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activateObject: this.activateObject,
      activeObjectIndex: null,
      activeObjectDescr: null,
      activeObjectData: null,
      activeObjectRecords: null,
      activeRecordQuery: null
    };

    this.createFormat();
  }

  componentDidMount() {
    this.activateInitial(0);
  }

  createFormat() {
    switch (this.props.blob.format) {
      case "record": {
        this.format = new RecordNlpFormat(this, this.props.shell.data);
        break;
      }
      case "multirc": {
        this.format = new MultiRCNlpFormat(this, this.props.shell.data);
        break;
      }
      case "schema": {
        this.format = new SchemaNlpFormat(this, this.props.shell.data);
        break;
      }
      default: {}
    }
  }

  /**
   * Object Selection
   */

  activateInitial(index) {
    if ( 0 === this.props.shell.data.objects.length ) {
      return;
    }

    this.state.activeObjectIndex = index;
    this.state.activeObjectDescr = this.props.shell.data.objects[index];

    // NEXT: Add caching
    this.loadObject(index);
  }

  activateObject = (index) => {
    if ( 0 === this.props.shell.data.objects.length ) {
      return;
    }

    this.setState({
      activeObjectIndex: index,
      activeObjectDescr: this.props.shell.data.objects[index]
    });

    // NEXT: Add caching
    this.loadObject(index);
  }

  loadObject(index) {
    const objectDescr = this.props.shell.data.objects[index];
    axios.get(`${Config.static_base_url()}/revisions/${this.props.revision.id}/${objectDescr["path"]}`)
      .then( response => {
        // Convert JSONL into records
        var records = [];
        var dataLines = response.data.split("\n");
        for (var dataLine of dataLines) {
          try {
            var record = JSON.parse(dataLine);
            records.push(record);
          } catch(err) {
            // Not a JSON record. Ignoring for now
          }
        }

        // Update component state
        this.setState({
          activeObjectData: response.data,
          activeObjectRecords: records
        });
      })
      .catch( error => {
      }
    );
  }

  /**
   * Rendering section
   */

  render() {
    if ( !this.format ) {
      // This should never be presented for a considerable amount of time unless critical error occurs.
      return (<></>);
    }

    const columns = this.format.getColumns();

    return (
      <>
        {this.props.shell.data.objects.length > 0 &&
          <DownloaderView
            app={this.props.app}
            parent={this.state}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.props.shell}
          />
        }

        <Card>
          <Card.Header>
            {this.props.revision
              ? <RevisionDetailsView
                  app={this.props.app}
                  revision={this.props.revision}
                  shell={null}
                />
              : <>Loading revision info...</>
            }
          </Card.Header>
          <Card.Body>
            {this.state.activeObjectRecords ? (
              <div
                className="ag-theme-quartz"
                style={{ height: "700px" }}
              >
                <AgGridReact
                  rowData={this.state.activeObjectRecords}
                  columnDefs={columns}
                  pagination={true}
                  paginationPageSize={20}
                  paginationPageSizeSelector={[20, 50, 100]}
                />
              </div>
            ) : (
              <>
                Loading object...
              </>
            )}
          </Card.Body>
        </Card>
      </>
    )
  }
}
