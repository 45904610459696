import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import { ReactFlowProvider } from "@xyflow/react";
import { GoPlus } from 'react-icons/go';
import FlowWrapper from "./wrapper.js";
import ControlsPane from "./controls/index.js";
import "./editor.css";

export default class EditorPane extends Component {
  render() {
    const disabled = this.props.shell.progress;
    return (
      <>
        <Card>
          <Card.Body>
            <div className="workflow-controls-pane-left">
              {!this.props.shell.library.visible &&
                <Button variant="light" onClick={() => {
                  this.props.shell.shellInterface.updatePane("library", true);
                }}><GoPlus/></Button>
              }
            </div>

            <ControlsPane
              shell={this.props.shell}
            />

            <div className="workflow-editor workflow-editor-surface">
              <ReactFlowProvider>
                <FlowWrapper
                  shell={this.props.shell}
                  shown={this.props.shown}
                  edgesUpdatable={!disabled}
                  edgesFocusable={!disabled}
                  nodesDraggable={!disabled}
                  nodesConnectable={!disabled}
                  nodesFocusable={!disabled}
                  panOnDrag={!disabled}
                  elementsSelectable={!disabled}
                  zoomOnDoubleClick={!disabled}
                />
              </ReactFlowProvider>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  }
}
