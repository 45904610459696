/**
 * Provides helpers for local URL generation
 */
export default class Navigation {
  /**
   * Path helpers
   */

  static selfUserProfilePath() {
    return `/p/me`;
  }

  static orgCreatePath(user) {
    if ( user.alias ) {
      return `/p/${user.alias}/org/create`;
    } else {
      return `/p/${user.id}/org/create`;
    }
  }

  static orgPath(org) {
    return `/${org.alias}`;
  }

  static userSettingsProfilePath(userId) {
    return `/p/${userId}/settings/profile`;
  }

  static userSettingsCredentialsPath(userId) {
    return `/p/${userId}/settings/credentials`;
  }

  static userSettingsLimitsPath(userId) {
    return `/p/${userId}/settings/limits`;
  }

  static orgSettingsProfilePath(orgId) {
    return `/${orgId}/settings/profile`;
  }

  static orgSettingsAccessPath(orgId) {
    return `/${orgId}/settings/access`;
  }

  static orgSettingsLimitsPath(orgId) {
    return `/${orgId}/settings/limits`;
  }

  static orgSettingsAdminPath(orgId) {
    return `/${orgId}/settings/admin`;
  }

  static blobCreatePath(org) {
    return `/${org.alias}/blob/create`;
  }

  static blobPath(org, blob) {
    return `/${org.alias}/${blob.alias}`;
  }

  static blobSettingsProfilePath(org, blob) {
    return `/${org.alias}/${blob.alias}/settings/profile`;
  }

  static blobSettingsProfilePath(org, blob) {
    return `/${org.alias}/${blob.alias}/settings/profile`;
  }

  static blobSettingsLimitsPath(org, blob) {
    return `/${org.alias}/${blob.alias}/settings/limits`;
  }

  static blobSettingsAccessPath(org, blob) {
    return `/${org.alias}/${blob.alias}/settings/access`;
  }

  static blobSettingsApiKeysPath(org, blob) {
    return `/${org.alias}/${blob.alias}/settings/api-keys`;
  }

  static blobSettingsAdminPath(org, blob) {
    return `/${org.alias}/${blob.alias}/settings/admin`;
  }

  /**
   * Workflows
   */

  static workflowPath(orgId, blobId, section, sectionId, subSectionId) {
    if ( subSectionId ) {
      return `/${orgId}/${blobId}/${section}/${sectionId}/${subSectionId}`;
    } else if ( sectionId ) {
      return `/${orgId}/${blobId}/${section}/${sectionId}`;
    } else if ( section ) {
      return `/${orgId}/${blobId}/${section}`;
    }
  }

  /**
   * Navigation helpers
   */

  /**
   * url contains fully-qualified path
   */
  static assign(url) {
    window.location.assign(url);
  }

  /**
   * path contains absolute URI path
   */
  static push(history, path) {
    history.push(path);
  }
}
