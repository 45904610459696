import Utils from "context/utils";
import Connections from "./connections.js";

export default class Converter {
  constructor(manifest) {
    this.manifest = manifest;
  }

  static deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static nodesToIndex(nodes) {
    let nodesIndex = {};
    for ( const node of nodes ) {
      nodesIndex[node.id] = node;
    }
    return nodesIndex;
  }

  /**
   * Post-processing
   */

  parseManifest() {
    this.manifest["components_index"] = {};
    for ( const componentGroup of this.manifest["component_groups"] ) {
      for ( const componentManifest of componentGroup["component_manifests"] ) {
        // Processors index
        this.manifest["components_index"][componentManifest["type"]] = componentManifest;

        // Ports index
        componentManifest["ports_index"] = {};
        for ( const port of componentManifest["ports"] ) {
          const key = port["category"] + "-" + ( port["route"] || "default" );
          componentManifest["ports_index"][key] = port;
        }
      }
    }
  }

  /**
   * Conversion
   */

  blobhubDefinitionToReactFlow(definition) {
    let nodes = [];
    let edges = [];

    // Create nodes
    definition["components"].forEach((processor) => {
      // Create new node
      let node = {
        type: "processorNode",
        id: processor["id"],
        position: processor["position"],
        data: {
          processor: Converter.deepCopy(processor),
          manifest: this.manifest["components_index"][processor["type"]]
        }
      };
      nodes.push(node);

      // Stub manifest when processor of a specified type is no longer supported
      if ( !node.data.manifest ) {
        node.data.processor.type = "no.longer.exists";
        node.data.manifest = {
          ports: []
        };
      }
    });

    // Create edges
    definition["connections"].forEach((connection) => {
      let edge = {
        id: connection["id"],
        source: connection["source_id"],
        sourceHandle: connection["source_port"],
        target: connection["target_id"],
        targetHandle: connection["target_port"],
        label: connection["route"],
        data: {
          route: connection["route"]
        }
      };

      // Flow connections are rendered as animated
      if ( Connections.isFlowConnection(edge) ) {
        edge.animated = true;
      }

      edges.push(edge);
    });

    return [ nodes, edges ];
  }

  reactFlowToBlobhubDefinition(nodes, edges) {
    let definition = {
      "type": "workflow_definition",
      "version": "1.0.1",
      "components": [],
      "connections": []
    };

    // Create components
    for ( const node of nodes ) {
      let processor = Converter.deepCopy(node.data.processor);
      processor.position = Converter.deepCopy(node.position);
      definition["components"].push(processor);
    }

    // Create connections
    for ( const edge of edges ) {
      let connection = {
        "id": edge.id,
        "source_id": edge.source,
        "target_id": edge.target,
        "source_port": edge.sourceHandle,
        "target_port": edge.targetHandle,
        "route": edge.data.route
      };
      definition["connections"].push(connection);
    }

    return definition;
  }

  /**
   * Creation
   */

  createNewNode(processorDefinition, position, nodes) {
    // Create processor based on definition
    const processor = {
      id: Utils.generateUniqueId(),
      category: processorDefinition["category"],
      type: processorDefinition["type"],
      name: processorDefinition["label"]
    };

    // Create React Flow node
    const node = {
      type: "processorNode",
      id: processor.id,
      position: position,
      data: {
        processor: processor,
        manifest: processorDefinition
      }
    };

    return node;
  }

  completeNewEdgeDefinition(nodes, edges, edge) {
    // Flow connections are rendered as animated
    if ( Connections.isFlowConnection(edge) ) {
      edge.animated = true;
    }

    // Pick the first route out of options available in the manifest
    let route = "default";
    let nodesIndex = Converter.nodesToIndex(nodes);
    const sourceNode = nodesIndex[edge.source];
    const sourceCategory = edge.sourceHandle;
    for ( const portManifest of sourceNode.data.manifest["ports"] ) {
      // Filter out other port categories
      if ( sourceCategory != portManifest["category"] ) {
        continue;
      }

      route = portManifest["route"];
      break;
    }

    // Populate additional connection properties
    edge.id = Utils.generateUniqueId();
    edge.label = route;
    edge.data = {
      route: route
    };
  }

  /**
   * Modification
   */

  doReactFlowChangesImpactDefinition(changes) {
    const impactingChanges = new Set([
      // Common changes
      "add", "remove",
      // Node changes
      "replace", "position",
      // Edge changes
      "reset"
    ]);

    for ( const change of changes ) {
      if ( impactingChanges.has(change.type) ) {
        return true;
      }
    }

    return false;
  }
}
