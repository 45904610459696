import React, { Component } from "react";
import Converter from "./converter";
import Utils from "context/utils";

export default class Synchronizer {
  constructor(app, revision, shell) {
    this.app = app;
    this.revision = revision;
    this.shell = shell;
    this.mounted = false;
  }

  /**
   * Persistence
   */

  savePlayground() {
    // Display progress
    this.shell.shellInterface.updateProgress(true, "Saving playground definition...", false, null);

    // Convert ReactFlow representation into BlobHub workflow definition object
    let state = this.shell.shellInterface.getState();
    const converter = new Converter(this.shell.shellInterface);
    const definitionObject = converter.reactFlowToBlobhubDefinition(state.nodes, state.edges);

    // Upload new definition
    const body = {
      engine: "workflow_blobhub",
      command: "upload_definition",
      definition_id: state.definitionId,
      definition: definitionObject
    };
    this.app.api.revisionsIdDataCommandPost(this.revision.id, body)
      .then( response => {
        // Remove progress
        this.shell.shellInterface.updateProgress(false, null, false, null);

        // Notify the view
        this.shell.shellInterface.updateState({
          definitionObjectBackup: definitionObject,
          modified: false
        });
      }, error => {
        // Render error state
        this.shell.shellInterface.updateProgress(
          false, null, true, "Failed to save workflow definition: " + error.message);
      });
  }

  revertPlayground() {
    // Recreate workspace state based on cached definition
    let state = this.shell.shellInterface.getState();
    const converter = new Converter(this.shell.shellInterface);
    const [initialNodes, initialEdges] = converter.blobhubDefinitionToReactFlow(
      state.definitionObjectBackup,
      state.manifest
    );

    // Notify the view
    this.shell.shellInterface.updateState({
      nodes: initialNodes,
      edges: initialEdges,
      modified: false
    });
  }

  /**
   * Initial Sequence
   *
   * The following flow is performed:
   * - loadPrerequisites
   * - loadWorkflowDefinitions
   * - loadPlaygroundManifest
   * - loadPlaygroundDefinitions
   *   - createDefaultPlayground (if non exists)
   * - loadPlaygroundDefinition
   * - loadSessions
   */

  loadPrerequisites() {
    this.loadWorkflowDefinitions();
  }

  loadWorkflowDefinitions() {
    // Indicate progress
    this.shell.shellInterface.updateProgress(true, "Loading workflow definitions...", false, null);

    // Load playground definitions
    const body = {
      engine: "workflow_blobhub",
      command: "list_definitions",
      category: "workflow"
    };
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Extract definitions from the response body
        const definitions = response.data.definitions;

        // Notify the view that definitions have been fetched
        this.shell.shellInterface.updateState({
          workflowDefinitions: definitions
        }).then(() => {
          this.loadPlaygroundManifest();
        });
      }, error => {
        // Indicate fatal failure
        this.shell.shellInterface.updateState({
          fatal: true, fatalMessage: "Failed to load workflow definitions: " + error.message
        });
      });
  }

  loadPlaygroundManifest() {
    // Indicate progress
    this.shell.shellInterface.updateProgress(true, "Loading playground manifest...", false, null);

    // Load manifest
    this.app.api.workflowManifestGet("playground")
      .then( response => {
        // Extract manifest from the response body
        let manifest = response.data.manifest;

        // Post process manifest data structure
        Converter.parseManifest(manifest);

        // Notify the view that the manifest has been fetched
        this.shell.shellInterface.updateState({
          manifest: manifest
        }).then(() => {
          this.loadPlaygroundDefinitions();
        });
      }, error => {
        // Indicate fatal failure
        this.shell.shellInterface.updateState({
          fatal: true, fatalMessage: "Failed to load playground manifest: " + error.message
        });
      });
  }

  loadPlaygroundDefinitions() {
    // Indicate progress
    this.shell.shellInterface.updateProgress(true, "Loading playground definitions...", false, null);

    // Load playground definitions
    const body = {
      engine: "workflow_blobhub",
      command: "list_definitions",
      category: "playground"
    };
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Extract definitions from the response body
        const definitions = response.data.definitions;

        // Notify the view that definitions have been fetched
        this.shell.shellInterface.updateState({
          definitions: definitions
        }).then(() => {
          // Single hardcoded definition is auto-created on demand for now.
          // Check if it is already there and proceed to loading right away.
          // Otherwise create new definition and then load it.
          if ( definitions.length > 0 ) {
            // Auto-select default definition
            const definition = definitions[0];

            // Apply current selection
            this.shell.shellInterface.updateState({
              definition: definition,
              definitionId: definition["id"]
            }).then(() => {
              this.loadPlaygroundDefinition();
            });
          } else {
            this.createDefaultPlayground();
          }
        });
      }, error => {
        // Indicate fatal failure
        this.shell.shellInterface.updateState({
          fatal: true, fatalMessage: "Failed to load playground definitions: " + error.message
        });
      });
  }

  createDefaultPlayground() {
    // Indicate progress
    this.shell.shellInterface.updateProgress(true, "Creating default playground definition...", false, null);

    // Create "default" definition
    const alias = "default";
    const body = {
      engine: "workflow_blobhub",
      command: "create_definition",
      category: "playground",
      alias: alias
    };
    this.app.api.revisionsIdDataCommandPost(this.revision.id, body)
      .then( response => {
        // Retrieve definition
        const definition = response.data["definition"];
        const definitionId = definition["id"];
        const definitions = [ definition ];

        // Notify the view
        this.shell.shellInterface.updateState({
          definitions: definitions,
          definitionId: definitionId,
          definition: definition
        }).then(() => {
          this.loadPlaygroundDefinition();
        });
      }, error => {
        // Indicate fatal failure
        this.shell.shellInterface.updateState({
          fatal: true, fatalMessage: "Failed to create definition: " + error.message
        });
      });
  }

  loadPlaygroundDefinition() {
    // Indicate progress
    this.shell.shellInterface.updateProgress(true, "Loading playground definition...", false, null);

    // Load definition object
    let state = this.shell.shellInterface.getState();
    const body = {
      engine: "workflow_blobhub",
      command: "download_definition",
      definition_id: state.definitionId
    };
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Extract definition from the response
        const definitionObject = response.data.definition_object;

        // Convert definition
        state = this.shell.shellInterface.getState();
        const converter = new Converter(this.shell.shellInterface);
        const [initialNodes, initialEdges] = converter.blobhubDefinitionToReactFlow(
          definitionObject,
          state.manifest
        );

        // Notify the view
        this.shell.shellInterface.updateState({
          definitionObjectBackup: Utils.deepCopy(definitionObject),
          nodes: initialNodes,
          edges: initialEdges
        }).then(() => {
          this.loadSessions();
        });
      }, error => {
        // Indicate fatal failure
        this.shell.shellInterface.updateState({
          fatal: true, fatalMessage: "Failed to load definition: " + error.message
        });
      });
  }

  loadSessions() {
    // Indicate progress
    this.shell.shellInterface.updateProgress(true, "Loading sessions...", false, null);

    // Fetch the list
    const body = {
      engine: "workflow_blobhub",
      command: "list_sessions"
    };
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Clear progress
        this.shell.shellInterface.updateProgress(false, null, false, null);

        // Extract the list from the response body
        const sessions = response.data.sessions;

        // Notify the view
        this.shell.shellInterface.updateState({
          sessions: sessions
        });
      }, error => {
        // Indicate fatal failure
        this.shell.shellInterface.updateState({
          fatal: true, fatalMessage: "Failed to load the list of sessions: " + error.message
        });
      });
  }

  /**
   * Session Initialization
   *
   * The following flow is performed:
   * - loadSessionContent
   * - loadSessionState
   *   - loadSessionObject
   */

  loadSessionContent() {
    this.loadSessionState();
  }

  loadSessionState() {
    const state = this.shell.shellInterface.getState();
    const body = {
      "engine": "workflow_blobhub",
      "command": "list_session_objects",
      "session_id": state.sessionId
    };
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Parse response
        const objects = response.data["objects"];

        // Update the view
        this.shell.shellInterface.updateState({
          sessionObjects: objects
        });

        // Load individual objects
        for ( const object of objects ) {
          this.loadSessionObject(object["alias"]);
        }
      }, error => {
        // Indicate failure
        this.shell.shellInterface.updateProgress(
          false, null, true, "Failed to load session objects: " + error.message);
      });
  }

  /**
   * Session Events Sync
   *
   * - autoRefreshSessionEvents
   *   - loadRecentSessionEvents
   *   - loadSessionEvents
   *     - refreshSessionObjects
   *     - loadSessionObject
   */

  autoRefreshSessionEvents() {
    // Stop timer altogether when unmounted
    if ( !this.mounted ) {
      return;
    }

    // Otherwise just keep on going...
    setTimeout(() => {
      const state = this.shell.shellInterface.getState();

      // Limit auto sync duration to 3 minutes
      if ( state.sessionSync ) {
        if ( state.sessionSyncCounter > 180 ) {
          this.shell.shellInterface.enableSessionSync(false);
        } else {
          this.shell.shellInterface.updateState({
            sessionSyncCounter: state.sessionSyncCounter + 1
          });
        }
      }

      // Fetch events only when
      // - in sync mode
      // - session is selected
      // - session is still open
      if ( state.sessionSync && null != state.session && "open" == state.session["status"] ) {
        // Fetch events
        this.loadRecentSessionEvents();
      }

      // Continue timer unconditionally
      this.autoRefreshSessionEvents();
    }, 1000);
  }

  loadRecentSessionEvents() {
    // Determine time of most recent event previously fetched
    let createdSince = null;
    const state = this.shell.shellInterface.getState();
    if ( state.sessionEventCreatedAt ) {
      createdSince = state.sessionEventCreatedAt;
    }
    this.loadSessionEvents(createdSince, true);
  }

  loadSessionEvents(createdSince, ascending) {
    const state = this.shell.shellInterface.getState();

    // Fetch the list
    const body = {
      "engine": "workflow_blobhub",
      "command": "list_session_events",
      "session_id": state.sessionId,
      "include_session": true,
      "ascending": ascending
    };
    if ( createdSince ) {
      body["created_since"] = createdSince;
    }
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Parse response
        const session = response.data["session"];
        const sessionEvents = response.data["session_events"];

        // Prepare state update
        let updatedState = {
          session: session,
          sessionEvents: sessionEvents
        };
        if ( sessionEvents.length > 0 ) {
          updatedState.sessionEventCreatedAt = sessionEvents[sessionEvents.length - 1]["created_at"];
        }

        // Update component state
        this.shell.shellInterface.updateState(updatedState);

        // Process events
        this.refreshSessionObjects(sessionEvents);
      }, error => {
        // Indicate failure
        this.shell.shellInterface.updateProgress(
          false, null, true, "Failed to load session events: " + error.message);
      });
  }

  refreshSessionObjects(sessionEvents) {
    let aliasesToRefresh = new Set();
    for ( const sessionEvent of sessionEvents ) {
      if ( "data.updated" == sessionEvent["type"] ) {
        aliasesToRefresh.add(sessionEvent["data_alias"]);
      }
    }

    for ( const alias of aliasesToRefresh ) {
      this.loadSessionObject(alias);
    }
  }

  loadSessionObject(alias) {
    const state = this.shell.shellInterface.getState();

    const body = {
      "engine": "workflow_blobhub",
      "command": "download_session_object",
      "session_id": state.sessionId,
      "alias": alias
    };
    this.app.api.revisionsIdDataQueryPost(this.revision.id, body)
      .then( response => {
        // Parse response
        const value = response.data["object"]["value"];

        // Update component state
        let sessionData = state.sessionData;
        sessionData[alias] = value;
        this.shell.shellInterface.updateState({
          sessionData: sessionData
        });
      }, error => {
        // Indicate failure
        this.shell.shellInterface.updateProgress(
          false, null, true, "Failed to load session object: " + error.message);
      });
  }

  /**
   * Session Operations
   */

  createNewSession() {
    // Render progress
    this.shell.shellInterface.updateProgress(true, "Creating a new session...", false, null);

    // Create session
    const body = {
      engine: "workflow_blobhub",
      command: "create_session"
    };
    this.app.api.revisionsIdDataCommandPost(this.revision.id, body)
      .then( response => {
        // Remove progress
        this.shell.shellInterface.updateProgress(false, null, false, null);

        // Retrieve session
        const session = response.data["session"];

        // Add to the list
        const state = this.shell.shellInterface.getState();
        const sessions = [session].concat(state.sessions);
        this.shell.shellInterface.updateState({sessions: sessions}).then(() => {
          // Apply new selection
          this.shell.shellInterface.sessionSelected(session);
        });
      }, error => {
        // Indicate failure
        this.shell.shellInterface.updateProgress(
          false, null, true, "Failed to create session: " + error.message);
      });
  }
}
