import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Alert } from 'react-bootstrap';
import { AiOutlineMail, AiOutlineLinkedin } from "react-icons/ai"
import './index.css'

export default class Footer extends Component {
  render() {
    return (
      <>
        <Container className="footer-separator">
          <hr/>
        </Container>

        <Container className="footer">
          © {(new Date().getFullYear())} BlobHub &nbsp;&nbsp;&nbsp;

          <Link to="/terms">Terms</Link> &nbsp;
          <Link to="/privacy">Privacy</Link> &nbsp;
          <Link to="/cookies">Cookies</Link>

          <span className="float-right">
            <a href="mailto:info@blobhub.io" target="__blank"><AiOutlineMail/></a>&nbsp;
            <a href="https://www.linkedin.com/company/blob-hub" target="__blank"><AiOutlineLinkedin/></a>
          </span>
        </Container>
      </>
    );
  }
}
