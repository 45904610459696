import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { GrRevert } from "react-icons/gr";
import { TfiSave } from "react-icons/tfi";
import { TbAnalyze } from "react-icons/tb";
import { BiExpandHorizontal, BiCollapseHorizontal } from "react-icons/bi";
import { GoInfo } from 'react-icons/go';
import "./index.css";

export default class View extends Component {
  handleRevert = () => {
    this.props.shell.shellInterface.revertWorkflow();
  }

  handleSave = () => {
    this.props.shell.shellInterface.saveWorkflow();
  }

  handleCheck = () => {
    this.props.shell.shellInterface.checkWorkflow();
  }

  toggleExpand = () => {
    this.props.shell.shellInterface.toggleWideContent();
  }

  render() {
    return (
      <>
        <div className="workflow-controls-pane-right">
          {this.props.shell.modified ? (
            <>
              <Button variant="light" onClick={this.handleRevert}><GrRevert /></Button>
              &nbsp;&nbsp;
              <Button variant="light" onClick={this.handleSave}><TfiSave /></Button>
            </>
          ) : (
            <>
              <Button variant="light" onClick={this.handleCheck}><TbAnalyze /></Button>
            </>
          )}

          &nbsp;&nbsp;
          <Button variant="light" onClick={this.toggleExpand}>
              {this.props.shell.wideContent
                ? <BiCollapseHorizontal />
                : <BiExpandHorizontal />}
          </Button>

          {!this.props.shell.details.visible &&
            <>
              &nbsp;&nbsp;
              <Button variant="light" onClick={() => {
                this.props.shell.shellInterface.updatePane("details", true);
              }}><GoInfo/></Button>
            </>
          }
        </div>
      </>
    );
  }
}
