import React, { Component } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, Form, Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { GoRepo, GoLock } from "react-icons/go";
import Navigation from "context/nav";

import Profile from "./profile";
import Limits from "components/controls/Limits/index";
import Credentials from "./credentials";

export default class UserSettings extends Component {
  render() {
    if ( !this.props.user ) {
      return (<></>);
    }

    const sectionId = this.props.match.params.sectionId || "profile";

    return (
      <>
        <Container className="blob-header-row content-row">
          <Row>
            <Col sm={3}>
              <Card>
                <ListGroup variant="flush" className="blob-settings-navigation-list">
                  {"profile" == sectionId
                    ? <ListGroup.Item active>Profile</ListGroup.Item>
                    : <Link to={Navigation.userSettingsProfilePath(this.props.userId)}>
                        <ListGroup.Item>Profile</ListGroup.Item>
                      </Link>
                  }
                  {"credentials" == sectionId
                    ? <ListGroup.Item active>Credentials</ListGroup.Item>
                    : <Link to={Navigation.userSettingsCredentialsPath(this.props.userId)}>
                        <ListGroup.Item>Credentials</ListGroup.Item>
                      </Link>
                  }
                  {"limits" == sectionId
                    ? <ListGroup.Item active>Limits</ListGroup.Item>
                    : <Link to={Navigation.userSettingsLimitsPath(this.props.userId)}>
                        <ListGroup.Item>Limits</ListGroup.Item>
                      </Link>
                  }
                </ListGroup>
              </Card>
            </Col>
            <Col sm={9}>
              <div className={"profile" == sectionId ? "" : "hidden"}>
                <Profile
                  history={this.props.history}
                  app={this.props.app}
                  user={this.props.user}
                  profileInterface={this.props.profileInterface}
                />
              </div>
              <div className={"credentials" == sectionId ? "" : "hidden"}>
                <Credentials
                  history={this.props.history}
                  app={this.props.app}
                  user={this.props.user}
                  profileInterface={this.props.profileInterface}
                />
              </div>
              <div className={"limits" == sectionId ? "" : "hidden"}>
                <Limits
                  history={this.props.history}
                  app={this.props.app}
                  user={this.props.user}
                  profileInterface={this.props.profileInterface}
                  target={"user"}
                  targetIds={["me"]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
