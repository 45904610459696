import React, { Component, Fragment } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { GoTrash, GoPencil, GoX, GoLock } from 'react-icons/go';
import { PiShippingContainer } from "react-icons/pi";
import { GoSync } from "react-icons/go";
import Select, { components } from "react-select";
import Styles from "../../controls/common/styles";
import Tooltip from "../../controls/common/tooltip";
import Utils from "context/utils";
import "./index.css";

export default class Selector extends Component {
  render() {
    const disabled = this.props.shell.modified;

    // Definitions select
    let currentDefinitionOption = null;
    let definitionOptions = [];
    for ( const definition of this.props.shell.definitions ) {
      const definitionOption = {
        value: definition,
        label: definition["alias"]
      };
      definitionOptions.push(definitionOption);
      if ( definition["id"] == this.props.shell.definitionId ) {
        currentDefinitionOption = definitionOption;
      }
    }

    // Sessions select
    let currentSessionOption = null;
    let sessionOptions = [];
    for ( const session of this.props.shell.sessions ) {
      let sessionOption = null;
      if ( session["id"] != this.props.shell.sessionId ) {
        sessionOption = {
          value: session,
          label: (
            <div className="playground-session-select-option">
              <p className="text-muted">{session["id"]}</p>
              <p>{Utils.formatDateTime(session["created_at"])}</p>
            </div>
          )
        };
      } else {
        sessionOption = {
          value: session,
          label: session["id"]
        };
        currentSessionOption = sessionOption;
      }
      sessionOptions.push(sessionOption);
    }

    return (
      <>
        <Container className="blob-header-row content-row workflow-selector-container">
          <div className="blob-download-row workflow-selector-row">
            {0 != this.props.shell.definitions.length &&
              <span>
                <span>
                  Playground&nbsp;&nbsp;&nbsp;
                </span>
                <span>
                  <div className="playground-selector-dropdown">
                    <Select
                      isDisabled={disabled}
                      options={definitionOptions}
                      value={currentDefinitionOption}
                      menuPortalTarget={document.body}
                      styles={Styles.headerSelect()}
                    />
                  </div>
                </span>
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <Tooltip text="Refresh workflow and playground definitions">
                    <Button variant="light" size="sm" className="align-top" disabled={disabled}
                      onClick={() => {
                        this.props.shell.shellInterface.getSync().loadPrerequisites();
                      }}>
                      <GoSync />
                    </Button>
                  </Tooltip>
                </span>
              </span>
            }
            {0 == this.props.shell.definitions.length &&
              <span>
                Create the first workflow definition here.
              </span>
            }

            <span className="float-right">
              <span>
                Session&nbsp;&nbsp;&nbsp;
              </span>
              <span>
                <div className="session-selector-dropdown">
                  <Select
                    isDisabled={disabled}
                    options={sessionOptions}
                    value={currentSessionOption}
                    onChange={(option) => {
                      const session = option.value;
                      this.props.shell.shellInterface.sessionSelected(session);
                    }}
                    menuPortalTarget={document.body}
                    styles={Styles.headerSelect()}
                  />
                </div>
              </span>
              <span>
                &nbsp;&nbsp;&nbsp;
                <Tooltip text="Create a new session">
                  <Button variant="success" size="sm" className="align-top" disabled={disabled}
                    onClick={() => {
                      this.props.shell.shellInterface.getSync().createNewSession();
                    }}>
                    <PiShippingContainer />
                  </Button>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;
                <Tooltip text="Refresh sessions">
                  <Button variant="light" size="sm" className="align-top" disabled={disabled}
                    onClick={() => {
                      this.props.shell.shellInterface.getSync().loadSessions();
                    }}>
                    <GoSync />
                  </Button>
                </Tooltip>
                &nbsp;&nbsp;&nbsp;
                <Form.Check
                  id="playground-session-sync-switch"
                  type="switch" label="Sync" inline
                  disabled={disabled}
                  checked={this.props.shell.sessionSync}
                  onChange={(event) => {
                    this.props.shell.shellInterface.enableSessionSync(event.target.checked);
                  }}
                />
              </span>
            </span>

            {disabled &&
              <span className="float-right">
                <div className="workflow-selector-locked-icon align-top" style={{"margin-left": "40px"}}>
                  <GoLock />&nbsp;
                </div>
                <div className="workflow-selector-locked-notice text-muted" style={{"width": "100px"}}>
                  Save or revert <br/> to unlock.
                </div>
              </span>
            }
          </div>
        </Container>
      </>
    );
  }
}
