import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Alert, Badge, ButtonGroup, Button } from 'react-bootstrap';
import { marked, Renderer } from 'marked';
import { AgGridReact } from 'ag-grid-react';
import { GoX, GoInfo } from "react-icons/go";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { FaRegStopCircle } from "react-icons/fa";
import Utils from 'context/utils';
import Preview from "./preview";
import "./index.css"

export default class ExecutionView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: true,
      autoRefresh: true,
      execution: null,
      executionEvents: null,
      currentProcessorId: null,
      eventsProgress: {
        progress: false,
        error: false,
        message: null
      },
      definition: null,
      definitionObject: null,
      definitionProgress: {
        progress: false,
        error: false,
        message: null
      }
    };

    this.mounted = false;
  }

  updateEventsProgress(progress, error, message) {
    this.setState({
      eventsProgress: {
        progress: progress,
        error: error,
        message: message
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    this.loadInitialEvents();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadInitialEvents() {
    this.loadExecutionEventsCore(null, null, false);
    this.continueAutoRefresh();
  }

  continueAutoRefresh() {
    if ( !this.mounted ) {
      return;
    }
    setTimeout(() => {
      // Issue refresh if enabled, and still in non-terminal state
      if ( this.state.autoRefresh && this.state.execution &&
        !this.props.app.api.hasWorkflowExecutionReachedTerminalStatus(this.state.execution) ) {
        this.loadNewerEvents();
      }

      // Continue timer unconditionally
      this.continueAutoRefresh();
    }, 1000);
  }

  loadOlderEvents() {
    let createdBefore = null;
    if ( this.state.executionEvents && this.state.executionEvents.length > 0 ) {
      createdBefore = this.state.executionEvents[0]["created_at"];
    }
    this.loadExecutionEventsCore(null, createdBefore, false);
  }

  loadNewerEvents() {
    let createdSince = null;
    if ( this.state.executionEvents && this.state.executionEvents.length > 0 ) {
      createdSince = this.state.executionEvents[this.state.executionEvents.length - 1]["created_at"];
    }
    this.loadExecutionEventsCore(createdSince, null, true);
  }

  loadExecutionEventsCore(createdSince, createdBefore, ascending) {
    // Render progress
    this.updateEventsProgress(true, false, "Loading events...");

    // Fetch the list
    const executionId = this.props.match.params.subSectionId;
    const body = {
      "engine": "workflow_blobhub",
      "command": "list_execution_events",
      "execution_id": executionId,
      "include_execution": true,
      "ascending": ascending
    };
    if ( createdSince ) {
      body["created_since"] = createdSince;
    }
    if ( createdBefore ) {
      body["created_before"] = createdBefore;
    }
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        // Parse response
        const execution = response.data["execution"];
        const executionEvents = response.data["execution_events"];

        // Clear progress
        this.updateEventsProgress(false, false, null);

        // Prepare state update
        let updatedState = {
          execution: execution
        };

        // Any new events received?
        if ( executionEvents.length > 0 ) {
          // Merge results with previously loaded ones
          let mergedExecutionEvents = [];
          mergedExecutionEvents.push(...( this.state.executionEvents || [] ));
          mergedExecutionEvents.push(...executionEvents);
          mergedExecutionEvents.sort((a, b) => a["created_at"].localeCompare(b["created_at"]));

          // Update current processor
          let currentProcessorId = null;
          if ( mergedExecutionEvents.length > 0 ) {
            const latestEvent = mergedExecutionEvents[mergedExecutionEvents.length - 1];
            if ( latestEvent["processor_id"] ) {
              currentProcessorId = latestEvent["processor_id"];
            }
          }

          updatedState = {...updatedState, ...{
            executionEvents: mergedExecutionEvents,
            currentProcessorId: currentProcessorId
          }};
        }

        // Update component state
        this.setState(updatedState, () => {
          this.loadDefinition();
        });
      }, error => {
        // Display failure message
        this.updateEventsProgress(false, true, "Failed to fetch the list of events: " + error.message);
      });
  }

  updateDefinitionProgress(progress, error, message) {
    this.setState({
      definitionProgress: {
        progress: progress,
        error: error,
        message: message
      }
    });
  }

  loadDefinition() {
    if ( null == this.state.execution || null != this.state.definition ) {
      return;
    }

    this.updateDefinitionProgress(true, false, null);

    const body = {
      engine: "workflow_blobhub",
      command: "download_definition",
      definition_id: this.state.execution["definition_id"]
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        const definition = response.data["definition"];
        const definitionObject = response.data["definition_object"];
        this.setState({
          definition: definition,
          definitionObject: definitionObject
        });
        this.updateDefinitionProgress(false, false, null);
      }, error => {
        this.updateDefinitionProgress(false, true, "Failed to load definition: " + error.message);
      });
  }

  render() {
    const lineHeight = 22;
    const cellStyle = {lineHeight: "${lineHeight}px"};
    let columns = [
      {
        "field": "created_at",
        "headerName": "Created At",
        "filter": false,
        "width": "125px",
        "sortable": false,
        cellStyle: cellStyle,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <>
              <span className="text-muted">
                {Utils.formatTime(params.data.created_at)}
              </span>
            </>
          );
        }
      },
      {
        "field": "type",
        "headerName": "Type",
        "filter": true,
        "width": "140px",
        "sortable": false,
        cellStyle: cellStyle,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <>
              <Badge bg="info" className="title-tag-badge">{params.value} </Badge>
            </>
          );
        }
      },
      {
        "field": "message",
        "headerName": "Message",
        "filter": true,
        "flex": 10,
        "sortable": false,
        cellStyle: cellStyle,
        wrapText: true,
        autoHeight: true,
        cellRenderer: params => {
          if ( "processor.output" == params.data.type || "processor.error" == params.data.type ) {
            var renderer = new Renderer();
            renderer.table = function(table) {
              let contents = marked.parse(table.raw);
              contents = contents.replace("<table>", '<table class="table table-hover">');
              return marked.parse(contents);
            };
            renderer.image = function(image) {
              return `<img src="${image.href}" alt="${image.text}" width="100%" />`;
            };
            var renderedMarkdown = {__html: marked.parse(params.value, { renderer: renderer })};
            return ( <span dangerouslySetInnerHTML={renderedMarkdown} /> );
          } else {
            return ( <> {params.value} </> );
          }
        }
      }
    ];
    if ( !this.state.details ) {
      columns.push({
        "field": "id",
        "headerName": "Details",
        "filter": false,
        "width": "300px",
        "sortable": false,
        cellStyle: cellStyle,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <>
              <small className="text-muted">
                {params.data.id}
                {params.data.processor_id &&
                  <> <br/> {params.data.processor_id} </>
                }
              </small>
            </>
          );
        }
      });
    }

    return (
      <>
        <Container>
          <Row>
            <Col sm={this.state.details ? 8 : 12}>
              <Card>
                <Card.Header>
                  Events
                  <span className="float-right">
                    {this.state.eventsProgress.progress &&
                      <span>{this.state.eventsProgress.message}</span>
                    }
                    {this.state.eventsProgress.error &&
                        <Alert variant="warning" style={{
                          "display": "inline-block",
                          "padding-top": "0px",
                          "padding-bottom": "0px"
                        }}>{this.state.eventsProgress.message}</Alert>
                    }
                    {!this.state.details &&
                      <ButtonGroup size="sm" className="header-options">
                        <Button variant="light" onClick={() => { this.setState({ details: true }) }}><GoInfo/></Button>
                      </ButtonGroup>
                    }
                  </span>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <Button disabled={this.state.eventsProgress.progress} variant="light" size="sm" onClick={() => {
                      this.loadOlderEvents();
                    }}><MdOutlineExpandLess /> Load Older Events</Button>
                  </Card.Text>
                  <Card.Text>
                    <div
                      className="ag-theme-quartz execution-events-grid"
                    >
                      <AgGridReact
                        rowData={this.state.executionEvents}
                        columnDefs={columns}
                        domLayout="normal"
                        pagination={false}
                        suppressScrollOnNewData={true}
                        animateRows={false}
                        autoSizeStrategy={{
                          type: "fitGridWidth"
                        }}
                        rowHeight={lineHeight}
                        onCellMouseOver={(event) => {
                          this.setState({}, () => {
                            let currentProcessorId = null;
                            if ( event.data && event.data["processor_id"] ) {
                              currentProcessorId = event.data["processor_id"];
                            }
                            if ( currentProcessorId != this.state.currentProcessorId ) {
                              this.setState({ currentProcessorId: currentProcessorId });
                            }
                          });
                        }}
                        onCellMouseOut={(event) => {
                          if ( null != this.state.currentProcessorId ) {
                            this.setState({ currentProcessorId: null });
                          }
                        }}
                        onRowDataUpdated={(event) => {
                          setTimeout(() => {
                            const index = event.api.getDisplayedRowCount() - 1;
                            event.api.ensureIndexVisible(index, "bottom");
                          }, 200);
                        }}
                        onFirstDataRendered={(event) => {
                          setTimeout(() => {
                            const index = event.api.getDisplayedRowCount() - 1;
                            event.api.ensureIndexVisible(index, "bottom");
                          }, 200);
                        }}
                      />
                    </div>
                  </Card.Text>
                  <Card.Text>
                    <Form.Row className="align-items-center">
                      <Col sm={3}>
                        <Button disabled={this.state.eventsProgress.progress} variant="light" size="sm" onClick={() => {
                          this.loadNewerEvents(null);
                        }}><MdOutlineExpandMore /> Load Newer Events</Button>
                      </Col>
                      <Col>
                        <Form.Check
                          id="execution-events-auto-refresh-switch"
                          type="switch" label="Auto refresh until terminal status"
                          checked={this.state.autoRefresh}
                          onChange={(event) => {
                            this.setState({ autoRefresh: event.target.checked });
                          }}
                        />
                      </Col>
                    </Form.Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4} className={this.state.details ? "" : "hidden"}>
              <Form.Group>
                <Card>
                  <Card.Header>
                    Details
                    <span className="float-right">
                      <ButtonGroup size="sm" className="header-options">
                        <Button variant="light" onClick={() => { this.setState({ details: false }) }}><GoX/></Button>
                      </ButtonGroup>
                    </span>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text as="h6">Execution</Card.Text>
                    <hr />
                    {this.state.execution ?
                      <Card.Text>
                        <table className="properties">
                          <tr>
                            <td className="prop-name-column">ID</td>
                            <td className="prop-value-column"><code>{this.state.execution.id}</code></td>
                          </tr>
                          <tr>
                            <td className="prop-name-column">Definition</td>
                            <td className="prop-value-column"><code>{this.state.execution["definition_id"]}</code></td>
                          </tr>
                          {this.state.definition &&
                            <tr>
                              <td className="prop-name-column"></td>
                              <td className="prop-value-column">{this.state.definition["alias"]}</td>
                            </tr>
                          }
                          <tr>
                            <td className="prop-name-column">Created At (UTC)</td>
                            <td className="prop-value-column">{Utils.formatDateTime(this.state.execution["created_at"])}</td>
                          </tr>
                          <tr>
                            <td className="prop-name-column"></td>
                            <td className="prop-value-column">{Utils.formatTimeAgo(this.state.execution["created_at"])}</td>
                          </tr>
                          <tr>
                            <td className="prop-name-column">Status</td>
                            <td>
                              <Badge bg="info" className="title-tag-badge">{this.state.execution.status}</Badge>
                            </td>
                          </tr>
                        </table>
                      </Card.Text> : <Card.Text>
                        <Alert variant="primary">
                          Loading execution details...
                        </Alert>
                      </Card.Text>
                    }
                    <Card.Text as="h6">Workflow</Card.Text>
                    <hr />
                    <Card.Text>
                      <Preview
                        app={this.props.app}
                        revision={this.props.revision}
                        execution={this.state.execution}
                        definition={this.state.definitionObject}
                        definitionProgress={this.state.definitionProgress}
                        currentProcessorId={this.state.currentProcessorId}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>

              <Form.Group>
                <Card>
                  <Card.Header>
                    Actions
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Button disabled={true}  variant="success" size="sm" onClick={() => {
                        // TODO:
                      }}><FaRegStopCircle /> Stop Execution</Button>
                    </Card.Text>
                    <Card.Text>
                      Terminates processing of an active execution leaving the rest of remaining flow unfinished.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
