import React, { Component } from 'react';
import { Container, Button, Form, Modal } from 'react-bootstrap';

export default class CreateSessionModal extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  handleCreate = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    this.props.handleCreate();

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create a New Session
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form} onSubmit={(event) => {
            event.preventDefault();
            this.handleCreate();
          }}>
            <Form.Group>
              <Form.Label>
                Session is a container for shared data and tie workflow executions together into a single stream of
                coherent activity.
              </Form.Label>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleCreate}>Create</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
