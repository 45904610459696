import BaseNlpFormat from './base';

export default class NlpFormat extends BaseNlpFormat {
  getKeyField() {
    return this.data.schema.id_field;
  }

  getColumns() {
    var columns = [];
    for (const schemaColumn of this.data.schema.columns) {
      const column = {
        "field": schemaColumn.property,
        "headerName": schemaColumn.name,
        "filter": true
      };
      columns.push(column);
    }
    return columns;
  }

  getDefaultSorted() {
    return [{
      dataField: this.getKeyField(),
      order: "asc"
    }];
  }
}
