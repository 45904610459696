import React, { Component } from 'react';
import { Nav, NavDropdown, Button } from 'react-bootstrap';
import Octicon, {Person} from '@github/octicons-react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import Config from "context/config";
import axios from 'axios';
import './user.css'

export default class UserMenu extends Component {
  responseGoogleSuccess = (response) => {
    // Check that response contains all the info we need
    if ( !response || !response.access_token ) {
      this.responseGoogleError({});
      return;
    }

    axios
      .get("https://www.googleapis.com/oauth2/v1/userinfo", {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
          Accept: "application/json"
        }
      })
      .then((userInfoResult) => {
        // Perform authentication
        this.props.app.api.authGoogleSigninPost(
          response.access_token,
          userInfoResult.data.id,
          userInfoResult.data.name,
          userInfoResult.data.email);
      })
      .catch((err) => {
        this.responseGoogleError(err);
      });
  }

  responseGoogleError = (error) => {
    // No op for now
  }

  signout = () => {
    // Sign out
    this.props.app.api.signout();

    // Navigate to home page
    // NEXT: This should be done more gracefully via router which is not currently accessible from here.
    window.location.assign("/");
  }

  render() {
    const LoginWrapper = ({shell, ...rest}) => {
      const login = useGoogleLogin({
        onSuccess: (response) => {
          shell.responseGoogleSuccess(response);
        },
        onError: (error) => {
          shell.responseGoogleError(error);
        }
      });

      return (
        <Button
          onClick={login}
          variant="outline-success"
          size="sm"
          className="btn-signin">
          Sign in
        </Button>
      )
    }

    const LogoutWrapper = ({shell, ...rest}) => {
      const logout = () => {
        // Cleanup library state
        googleLogout();

        // Cleanup app state
        shell.signout();
      };

      return (
        <NavDropdown.Item onClick={logout}>Sign out</NavDropdown.Item>
      )
    }

    const userDropDownTitle = (
      <span className="justify-content-end global-user-avatar">
        <Octicon icon={Person} size="medium"/>
      </span>
    );

    return (
      <>
        <>
          {this.props.app.authenticated && this.props.app.auth && !this.props.app.auth.anonymous ? (
            <NavDropdown title={userDropDownTitle} id="basic-nav-dropdown" alignRight>
              <NavDropdown.Item href="/p/me">Profile</NavDropdown.Item>
              <NavDropdown.Divider/>
              <LogoutWrapper shell={this}/>
            </NavDropdown>
          ) : (
            <LoginWrapper shell={this}/>
          )}
        </>
      </>
    )
  }
}
