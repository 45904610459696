import { Component } from "react";
import { Card, Alert } from "react-bootstrap";
import Widget from "../common/widget";
import Message from "../common/message";
import Converter from "../../converter";

export default class MessageNode extends Component {
  render() {
    // Find messages data
    const [ messageValue, warning ] = Widget.loadObjectValue(
      this.props.data.shellInterface, this.props.data.component, "message", "message");

    // Render message content
    return (
      <Widget data={this.props.data}>
        {warning &&
          <Alert variant="info">
            {warning}
          </Alert>
        }
        {!warning &&
          <Message value={messageValue} />
        }
      </Widget>
    );
  }
}
