export default class Styles {
  static headerSelect() {
    return {
      control: base => ({
        ...base,
        height: 31,
        minHeight: 31,
        fontSize: '13px'
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '31px',
        padding: '0 6px'
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px'
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '31px',
      }),
      menuPortal: base => ({
        ...base,
        zIndex: 10
      })
    };
  }
}