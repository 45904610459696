import React, { Component } from 'react';
import { Container, Button, Alert, Form, Row, Col, Table, Badge } from 'react-bootstrap';
import Utils from "context/utils";

export default class Limits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limits: null,
      loading: true,
      notFound: false
    };
  }

  componentDidMount() {
    this.loadLimits();
  }

  componentDidUpdate(prevProps) {
    if ( "blob" == this.props.target ) {
      if (this.props.blob != prevProps.blob) {
        this.loadLimits();
      }
    }
  }

  static limitDetails = {
    // User limits
    orgs_per_user: {
      description: "Number of organizations to be created per user account",
      target: "user",
      type: "numeric",
      unit: "quantity"
    },

    // Org limits
    blobs_per_org: {
      description: "Number of blobs to be created per organization",
      target: "org",
      type: "numeric",
      unit: "quantity"
    },

    // Blob limits
    data_domains: {
      description: "The hierarchy of data domains available for blob creation",
      target: "blob",
      type: "metadata"
    },
    revisions_per_blob: {
      description: "Number of revisions to be created per blob",
      target: "blob",
      type: "numeric",
      unit: "quantity"
    },
    space_per_revision: {
      description: "Amount of space allocated per prevision",
      target: "blob",
      type: "metadata"
    },
    workflow_definitions_per_revision: {
      description: "Number of workflow definitions per revision",
      target: "blob",
      type: "numeric",
      unit: "quantity"
    },
    workflow_sessions_per_revision: {
      description: "Number of workflow sessions per revision",
      target: "blob",
      type: "numeric",
      unit: "quantity"
    },
    workflow_executions_per_session: {
      description: "Number of workflow executions per session",
      target: "blob",
      type: "numeric",
      unit: "quantity"
    },
  };

  loadLimits() {
    // Query limits
    var apiMethod = null;
    if ( "user" == this.props.target ) {
      apiMethod = this.props.app.api.usersIdLimitsGet;
    } else if ( "org" == this.props.target ) {
      apiMethod = this.props.app.api.orgsIdLimitsGet;
    } else if ( "blob" == this.props.target ) {
      apiMethod = this.props.app.api.blobsIdLimitsGet;
    } else {
      this.setState({ notFound: true });
      return;
    }
    apiMethod.call(this.props.app.api, ...this.props.targetIds)
      .then(response => {
        var limits = [];
        const limitsDict = response.data.limits;
        for ( const limitId in limitsDict ) {
          const limit = limitsDict[limitId];
          limit.id = limitId;
          limit.type = Limits.limitDetails[limitId].type;
          limit.target = Limits.limitDetails[limitId].target;
          limit.description = Limits.limitDetails[limitId].description;
          limit.unit = Limits.limitDetails[limitId].unit;
          limits.push(limit);
        }

        limits = limits.sort((l1, l2) => {
          if ( l1.target == l2.target ) {
            return l1.type < l2.type ? -1 : 1;
          } else if ( l1.target == this.props.target ) {
            return -1;
          } else if ( l2.target == this.props.target ) {
            return 1;
          } else {
            return l1.target < l2.target ? -1 : 1;
          }
        });

        this.setState({
          limits: limits,
          loading: false
        });
      }, error => {
        this.setState({
          notFound: true,
          loading: false
        });
      });
  }

  formatValue(unit, value) {
    if ( "quantity" == unit ) {
      return value;
    } else if ( "bytes" == unit ) {
      return Utils.formatBytes(value);
    } else {
      return value;
    }
  }

  formatMetadata(limitId, value) {
    if ( "data_domains" == limitId ) {
      const domains = [];
      for ( var domain of value ) {
        for ( var type of domain.types ) {
          for ( var format of type.formats ) {
            domains.push({
              domain: domain.id,
              type: type.id,
              format: format.id
            });
          }
        }
      }
      return (
        <>
          <td colspan={2}>
            {domains.map((domain, index) => {
              return (
                <div className="blob-item-row">
                  <Badge variant="secondary" className="title-tag-badge">domain: <strong>{domain.domain}</strong></Badge>
                  &nbsp;&nbsp;
                  <Badge variant="secondary" className="title-tag-badge">type: <strong>{domain.type}</strong></Badge>
                  &nbsp;&nbsp;
                  <Badge variant="secondary" className="title-tag-badge">format: <strong>{domain.format}</strong></Badge>
                </div>
              );
            })}
          </td>
        </>
      );
    } else if ( "space_per_revision" == limitId ) {
        return (
          <>
            <td>
              Value
            </td>
            <td>
              {Utils.formatBytes(value)}
            </td>
          </>
        );
    } else {
      return (
        <>
          <td colspan={2}>
            <code>
              {JSON.stringify(value, null, 1)}
            </code>
          </td>
        </>
      );
    }
  }

  render() {
    return (
      <>
        <h5>Limits</h5>
        <hr/>

        {this.state.limits &&
          <div>
            <Table hover borderless>
              <thead style={{ "border-bottom": "2px solid #dee2e6" }}>
                <tr>
                  <th width="10%">Target</th>
                  <th width="46%">Description</th>
                  <th width="22%">Details</th>
                  <th width="22%"></th>
                </tr>
              </thead>
              <tbody>
                {this.state.limits.map((limit, index) => {
                  const backgroundColor = ( this.props.target == limit.target ) ? "#f6f6f6" : "#ffffff";
                  if ( "numeric" == limit.type ) {
                    return (
                      <tr style={{"background-color": backgroundColor}}>
                        <td>
                          <pre>{limit.target}</pre>
                        </td>
                        <td>
                          {limit.description} (<strong>{limit.id}</strong>)
                        </td>
                        <td>
                          {limit.target == this.props.target ? (
                            <>
                              {limit.actual != undefined ? <>Usage</> : <>Maximum</>}
                            </>
                          ) : (
                            <>
                              Maximum
                            </>
                          )}
                        </td>
                        <td>
                          {limit.target == this.props.target ? (
                            <>
                              {limit.actual != undefined ?
                                <>
                                  <strong>{this.formatValue(limit.unit, limit.actual)}</strong>/
                                  {this.formatValue(limit.unit, limit.maximum)}<br/>
                                  <Form.Text muted>
                                    ({(limit.actual * 100.0/limit.maximum).toFixed(1)}%)
                                  </Form.Text>
                                </> :
                                <>
                                  {this.formatValue(limit.unit, limit.maximum)}
                                </>
                              }
                            </>
                          ) : (
                            <>
                              {this.formatValue(limit.unit, limit.maximum)} <br/>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  } else if ( "metadata" == limit.type ) {
                    return (
                      <tr style={{"background-color": backgroundColor}}>
                        <td>
                          <pre>{limit.target}</pre>
                        </td>
                        <td>
                          {limit.description} (<strong>{limit.id}</strong>)
                        </td>
                        {this.formatMetadata(limit.id, limit.value)}
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </div>
        }
        {this.state.notFound &&
          <div className="content-row">
            Failed to load object limits.
          </div>
        }
        {this.state.loading &&
          <div className="content-row">
            Loading limits...
          </div>
        }
      </>
    );
  }
}
