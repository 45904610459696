import { Component } from "react";
import { Button } from "react-bootstrap";
import { LuClipboardCopy } from "react-icons/lu";
import Tooltip from "./tooltip";

export default class Clipboard extends Component {
  render() {
    return (
      <Tooltip text="Copy to clipboard">
        <Button size="sm" variant="light" onClick={(event) => {
          try {
            navigator.clipboard.writeText(this.props.text);
          } catch (error) {
            console.error(error.message);
          }
        }}>
          <LuClipboardCopy />
        </Button>
      </Tooltip>
    );
  }
}
