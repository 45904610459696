import { useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { GoArrowDown } from 'react-icons/go';
import { MdInput, MdOutput } from "react-icons/md";
import { VscSymbolInterface } from "react-icons/vsc";

function ProcessorNode({ data, isConnectable }) {
  let portByType = {
    "flow_input": [],
    "flow_output": [],
    "data_input": [],
    "data_output": [],
    "provider_input": [],
    "provider_reference": [],
    "data_reference": [],
  }
  for ( const port of data.manifest["ports"] ) {
    portByType[port["category"]].push(port);
  }

  return (
    <div className="generic-node">
      <div className="node-property-row">
        <label htmlFor="text" className="node-property-label">Type</label>
        <label htmlFor="text" className="node-property-type">{data.processor.type}</label>
      </div>
      <div className="node-property-row-last">
        <label htmlFor="text" className="node-property-label">Name</label>
        <label htmlFor="text" className="node-property-name"><strong>{data.processor.name}</strong></label>
      </div>

      {portByType["flow_input"].length > 0 &&
        <Handle
          type="target"
          id="flow_input"
          style={{
            left: 20
          }}
          isConnectable={true}
        >
          <GoArrowDown />
        </Handle>
      }

      {portByType["flow_output"].length > 0 &&
        <Handle
          type="source"
          position={Position.Bottom}
          id="flow_output"
          style={{
            left: 20
          }}
          isConnectable={true}
        >
          <GoArrowDown />
        </Handle>
      }

      {portByType["data_input"].length > 0 &&
        <Handle
          type="source"
          id="data_input"
          style={{
            left: "auto",
            right: 5
          }}
          isConnectable={true}
        >
          <MdInput />
        </Handle>
      }

      {portByType["data_output"].length > 0 &&
        <Handle
          type="source"
          position={Position.Bottom}
          id="data_output"
          style={{
            left: "auto",
            right: 5
          }}
          isConnectable={true}
        >
          <MdOutput />
        </Handle>
      }

      {portByType["provider_input"].length > 0 &&
        <Handle
          type="source"
          id="provider_input"
          position={Position.Left}
          isConnectable={true}
        >
          <VscSymbolInterface />
        </Handle>
      }

      {portByType["provider_reference"].length > 0 &&
        <Handle
          type="target"
          id="provider_reference"
          position={Position.Right}
          isConnectable={true}
        >
          <VscSymbolInterface />
        </Handle>
      }

      {portByType["data_reference"].length > 0 &&
        <Handle
          type="target"
          id="data_reference"
          position={Position.Left}
          isConnectable={true}
        >
          <VscSymbolInterface />
        </Handle>
      }
    </div>
  );
}

export default ProcessorNode;
