import { Component } from "react";
import { Card, Alert, Row, Col, Form, Button } from "react-bootstrap";
import { VscSend } from "react-icons/vsc";
import { GrInProgress } from "react-icons/gr";
import { VscDebugLineByLine } from "react-icons/vsc";
import { FaCheck, FaRegStopCircle } from "react-icons/fa";
import { PiWarningBold } from "react-icons/pi";
import Tooltip from "../../../controls/common/tooltip";
import Widget from "../common/widget";
import Message from "./../common/message";
import Converter from "../../converter";
import Utils from "context/utils"
import "./index.css";

export default class ChatNode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputMessage: "",
      execution: null,
      progress: false,
      progressMessage: null,
      error: false,
      errorMessage: null,
    };

    this.activeStatuses = new Set(["creating", "running"]);
    this.inactiveStatuses = new Set(["completed", "failed"]);

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    this.continueAutoRefresh();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    // Detect session<->execution mismatch and wipe local state if that happens
    const state = this.props.data.shellInterface.getState();
    if ( this.state.execution && this.state.execution["session_id"] != state.sessionId ) {
      this.setState({
        execution: null,
        progress: false, progressMessage: null, error: false, errorMessage: null
      });
    }
  }

  canSendMessage() {
    return (
      this.state.inputMessage
      && !this.state.progress
      && !( this.state.execution && this.activeStatuses.has(this.state.execution["status"]) )
    );
  }

  sendMessage() {
    // Dont proceed if there is an active execution or there is no message
    if ( !this.canSendMessage() ) {
      return;
    }

    // Enable session synchronization
    this.props.data.shellInterface.enableSessionSync(true);

    // Clear state prior to starting an execution
    this.setState({
      inputMessage: "",
      execution: null,
      progress: true, progressMessage: "Initiating workflow execution...",
      error: false, errorMessage: null
    });

    // Construct workflow input
    const userInput = Converter.getComponentPortValueData(this.props.data.component, "user_input");
    const definitionId = Converter.getComponentPortValueData(this.props.data.component, "send_message_workflow");
    const inputData = [
      {
        "alias": "user_input",
        "value": {
          "type": "message",
          "message": {
            "role": "user",
            "content": [
              {
                "type": "text",
                "text": this.state.inputMessage
              }
            ]
          }
        }
      }
    ];

    const state = this.props.data.shellInterface.getState();
    const sessionId = state.sessionId;
    const revisionId = this.props.data.shellInterface.getRevision()["id"];
    const body = {
      "engine": "workflow_blobhub",
      "command": "create_execution",
      "session_id": sessionId,
      "definition_id": definitionId,
      "input_data": inputData
    };
    this.props.data.shellInterface.getApp().api.revisionsIdDataCommandPost(revisionId, body)
      .then( response => {
        // Parse response
        const execution = response.data["execution"];

        // Indicate success
        this.setState({
          execution: execution,
          progress: false, progressMessage: null, error: false, errorMessage: null
        });
      }, error => {
        // Indicate failure
        this.setState({
          progress: false, progressMessage: null,
          error: true, errorMessage: "Failed to start workflow execution: " + error.message
        });
      });
  }

  continueAutoRefresh() {
    if ( !this.mounted ) {
      return;
    }
    setTimeout(() => {
      // Issue refresh if execution is still in one of active statuses
      if ( this.state.execution && this.activeStatuses.has(this.state.execution["status"]) ) {
        this.refreshExecution();
      }

      // Continue timer unconditionally
      this.continueAutoRefresh();
    }, 1000);
  }

  refreshExecution() {
    const executionId = this.state.execution["id"];
    const revisionId = this.props.data.shellInterface.getRevision()["id"];
    const body = {
      "engine": "workflow_blobhub",
      "command": "get_execution",
      "execution_id": executionId
    };
    this.props.data.shellInterface.getApp().api.revisionsIdDataQueryPost(revisionId, body)
      .then( response => {
        // Parse response
        const execution = response.data["execution"];

        // Update state
        this.setState({ execution: execution });
      }, error => {
        // Stop refreshing and hide execution details
        this.setState({ execution: null });
      });
  }

  createExecutionPath() {
    const state = this.props.data.shellInterface.getState();
    const sessionId = state.sessionId;
    const executionId = this.state.execution.id;
    return this.props.data.shellInterface.createWorkflowPath("debugger", sessionId, executionId);
  }

  stopExecution() {
    // TODO:
  }

  render() {
    // Widget dimensions
    const width = Converter.getComponentPortValueData(this.props.data.component, "width");
    const height = Converter.getComponentPortValueData(this.props.data.component, "height");

    // Find history data
    const [ historyValue, historyWarning ] = Widget.loadObjectValue(
      this.props.data.shellInterface, this.props.data.component, "history", "messages");
    let history = historyValue ? historyValue["messages"] : null;
    if ( history ) {
      history = [...history];
      history.reverse();
    }

    // Find user input and message workflow details
    const userInput = Converter.getComponentPortValueData(this.props.data.component, "user_input");
    const sendMessageWorkflow = Converter.getComponentPortValueData(this.props.data.component, "send_message_workflow");
    const workflowWarning
      = userInput && sendMessageWorkflow
      ? null : "\"user_input\" or \"send_message_workflow\" value is not specified.";

    // Retrieve playground state
    const state = this.props.data.shellInterface.getState();

    // Render chat content
    return (
      <Card className="playground-chat-node">
        <Card.Header>
          {this.props.data.component.name}
          <span className="float-right text-muted">
            {this.props.data.component.type}
          </span>
        </Card.Header>
        <Card.Body className="nodrag nowheel" style={{
          "width": `${width}px`,
          "height": `${height}px`,
          "max-height": `${height}px`,
          "overflow": "scroll"
        }}>
          {historyWarning &&
            <Alert variant="info">
              {historyWarning}
            </Alert>
          }
          {(!historyWarning && !history) &&
            <Alert variant="warning">
              History object contains no value.
            </Alert>
          }
          {(!historyWarning && history) &&
            <>
              {history.map((messageValue, index) => (
                <Message
                  value={messageValue}
                  defaultContentView={"markdown"}
                />
              ))}
            </>
          }
        </Card.Body>

        {this.state.progress &&
          <>
            <hr className="playground-chat-node-separator"/>
            <Card.Body className="nodrag nowheel">
              <Alert variant="primary">
                {this.state.progressMessage}
              </Alert>
            </Card.Body>
          </>
        }
        {this.state.error &&
          <>
            <hr className="playground-chat-node-separator"/>
            <Card.Body className="nodrag nowheel">
              <Alert variant="warning">
                {this.state.errorMessage}
              </Alert>
            </Card.Body>
          </>
        }
        {this.state.execution &&
          <>
            {this.activeStatuses.has(this.state.execution["status"]) &&
              <>
                <hr className="playground-chat-node-separator"/>
                <Card.Body className="nodrag nowheel">
                  <Card.Text>
                    <Row className="align-items-center">
                      <Col xs="auto" style={{ "padding-right": "0px" }}>
                        <GrInProgress />
                      </Col>
                      <Col>
                        <span>
                          Workflow is running... <br/>
                          <span className="text-muted" style={{ "text-wrap": "wrap" }}>
                            Created: {Utils.formatTimeAgo(this.state.execution["created_at"])}
                          </span>
                        </span>
                      </Col>
                      <Col xs="auto">
                        <Tooltip text="Open in Debugger">
                          <Button size="sm" variant="light">
                            <a href={this.createExecutionPath()}>
                              <VscDebugLineByLine />
                            </a>
                          </Button>
                        </Tooltip>
                        &nbsp;&nbsp;
                        <Tooltip text="Stop execution">
                          <Button size="sm" variant="light" onClick={(event) => {
                            this.stopExecution();
                          }}>
                            <FaRegStopCircle />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </>
            }
            {this.inactiveStatuses.has(this.state.execution["status"]) &&
              <>
                <hr className="playground-chat-node-separator"/>
                <Card.Body className="nodrag nowheel">
                  <Card.Text>
                    <Row className="align-items-center">
                      <Col xs="auto" style={{ "padding-right": "0px" }}>
                        {"completed" == this.state.execution["status"] &&
                          <FaCheck />
                        }
                        {"failed" == this.state.execution["status"] &&
                          <PiWarningBold />
                        }
                      </Col>
                      <Col>
                        <span>
                          Workflow execution completed. <br/>
                          <span className="text-muted" style={{ "text-wrap": "wrap" }}>
                            Status: {this.state.execution["status"]}
                          </span>
                        </span>
                      </Col>
                      <Col xs="auto">
                        <Tooltip text="Open in Debugger">
                          <Button size="sm" variant="light">
                            <a href={this.createExecutionPath()}>
                              <VscDebugLineByLine />
                            </a>
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </>
            }
          </>
        }

        {workflowWarning &&
          <>
            <hr className="playground-chat-node-separator"/>
            <Card.Body className="nodrag nowheel">
              <Alert variant="info">
                {workflowWarning}
              </Alert>
            </Card.Body>
          </>
        }
        {(state.session && !workflowWarning) &&
          <>
            <hr className="playground-chat-node-separator"/>
            <Card.Body className="nodrag nowheel">
              <Card.Text>
                <Row className="align-items-center">
                  <Col style={{ "padding-right": "0px" }}>
                    <Form onSubmit={(event) => {
                      event.preventDefault();
                      this.sendMessage();
                    }}>
                      <Form.Control
                        type="text" size="sm"
                        placeholder="Type your message here..."
                        value={this.state.inputMessage}
                        onChange={(event) => {
                          this.setState({ inputMessage: event.target.value })
                        }}
                      />
                    </Form>
                  </Col>
                  <Col xs="auto">
                    <Tooltip text="Send a message">
                      <Button size="sm" variant="light" disabled={!this.canSendMessage()} onClick={(event) => {
                        this.sendMessage();
                      }}><VscSend/></Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </>
        }
      </Card>
    );
  }
}
