import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import { GoX } from "react-icons/go";
import PlaygroundDetails from "./playground";
import ConnectionDetails from "./connection";
import ComponentDetails from "./component";

export default class DetailsPane extends Component {
  renderContent() {
    if ( "component" == this.props.shell.details.view ) {
      return ( <ComponentDetails shell={this.props.shell} /> );
    } else if ( "connection" == this.props.shell.details.view ) {
      return ( <ConnectionDetails shell={this.props.shell} /> );
    } else {
      return ( <PlaygroundDetails shell={this.props.shell} /> );
    }
  }

  render() {
    return (
      <>
        <Card>
          <Card.Body className="workflow-library-components">
            <span className="workflow-controls-pane-right">
              <Button variant="light" onClick={() => {
                this.props.shell.shellInterface.updatePane("details", false);
              }}><GoX/></Button>
            </span>

            {this.renderContent()}
          </Card.Body>
        </Card>
      </>
    );
  }
}
