import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import {
  ReactFlow,
  ReactFlowProvider,
  applyNodeChanges,
  Background,
  BackgroundVariant,
  Handle,
  Position
} from '@xyflow/react';
import Connections from "../../editor/connections";
import "./preview.css";

function ProcessorNode({ data, isConnectable }) {
  return (
    <div className="generic-node">
      <div className="node-property-row">
        <label htmlFor="text" className="node-property-label">Type</label>
        <label htmlFor="text" className="node-property-type">{data.processor.type}</label>
      </div>
      <div className="node-property-row-last">
        <label htmlFor="text" className="node-property-label">Name</label>
        <label htmlFor="text" className="node-property-name"><strong>{data.processor.name}</strong></label>
      </div>

      <Handle type="target" position={Position.top} id="flow_input" style={{ left: 15 }} isConnectable={true}></Handle>
      <Handle type="source" position={Position.Bottom} id="flow_output" style={{ left: 15 }} isConnectable={true}></Handle>
      <Handle type="source" position={Position.top} id="data_input" style={{ right: 8, left: "auto" }} isConnectable={true}></Handle>
      <Handle type="source" position={Position.Bottom} id="data_output" style={{ right: 8, left: "auto" }} isConnectable={true}></Handle>
      <Handle type="source" position={Position.Left} id="provider_input" isConnectable={true}></Handle>
      <Handle type="target" position={Position.Right} id="provider_reference" isConnectable={true}></Handle>
      <Handle type="target" position={Position.Left} id="data_reference" isConnectable={true}></Handle>
    </div>
  );
}

export default class Preview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nodes: [],
      edges: []
    };

    this.nodeTypes = {
      processorNode: ProcessorNode,
    };
  }

  componentDidMount() {
    this.parseDefinition();
  }

  componentDidUpdate(prevProps) {
    if ( null != this.props.definition && null == prevProps.definition ) {
      this.parseDefinition();
    }

    if ( this.props.currentProcessorId !== prevProps.currentProcessorId ) {
      let nodes = [];
      for ( const node of this.state.nodes ) {
        node.selected = node.data.processor["id"] == this.props.currentProcessorId;
        nodes.push({...node});
      }
      this.setState({ nodes: nodes });
    }
  }

  parseDefinition() {
    if ( null == this.props.definition ) {
      return;
    }

    let nodes = [];
    let edges = [];

    for ( const processor of this.props.definition["components"] ) {
      let node = {
        type: "processorNode",
        id: processor["id"],
        position: processor["position"],
        data: {
          processor: processor
        },
        selected: processor["id"] == this.props.currentProcessorId
      };
      nodes.push(node);
    }

    for ( const connection of this.props.definition["connections"] ) {
      let edge = {
        id: connection["id"],
        source: connection["source_id"],
        sourceHandle: connection["source_port"],
        target: connection["target_id"],
        targetHandle: connection["target_port"],
        label: connection["route"]
      };

      // Flow connections are rendered as animated
      if ( Connections.isFlowConnection(edge) ) {
        edge.animated = true;
      }

      edges.push(edge);
    }

    this.setState({
      nodes: nodes,
      edges: edges
    });
  }

  render() {
    return (
      <>
        {( null == this.props.execution || this.props.definitionProgress.progress ) &&
          <Alert variant="primary">
            Loading execution details...
          </Alert>
        }
        {this.props.definitionProgress.error &&
          <Alert variant="warning">
            Failed to fetch definition associated with this execution. Does it still exist?
          </Alert>
        }
        {null != this.props.definition &&
          <div className="workflow-preview workflow-preview-surface">
            <ReactFlowProvider>
              <ReactFlow
                nodeTypes={this.nodeTypes}
                nodes={this.state.nodes}
                edges={this.state.edges}
                elementsSelectable={false}
                fitView
                attributionPosition="bottom-right"
                minZoom={0.1}
                maxZoom={2}
              >
                <Background id="preview-background" variant={BackgroundVariant.Dots} gap={12} size={1} />
              </ReactFlow>
            </ReactFlowProvider>
          </div>
        }
      </>
    );
  }
}
