import React from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';

import InfoTooltip from 'components/controls/InfoTooltip/index'

import TaggerView from './../tagger';
import BaseNlpFormat from './base';

export default class NlpFormat extends BaseNlpFormat {
  constructor(parent, data) {
    super(parent, data);

    this.activeRecordQuery = null;
  }

  /**
   * BaseNlpFormat
   */

  getKeyField() {
    return "idx";
  }

  getColumns() {
    return [{
        "field": "idx",
        "headerName": "ID",
        "filter": true,
        "flex": 1
      }, {
        "field": "source",
        "headerName": "Source",
        "filter": true,
        "flex": 1
      }, {
        "field": "passage.text",
        "headerName": "Passage Text",
        "filter": true,
        "flex": 5
      }];
  }

  getDefaultSorted() {
    return [{
      dataField: "idx",
      order: "asc"
    }];
  }

  getRowExpansionOptions() {
    return {
      renderer: this.expandedRowRender,
      onlyOneExpanding: true
    };
  }

  /**
   * Row Expansion
   */

  findMatchingTags(text, pattern, badge) {
    var tags = [];
    var startOffset = 0;
    while ( -1 !== startOffset ) {
      var foundOccurrence = text.indexOf(pattern, startOffset);
      if ( -1 !== foundOccurrence ) {
        tags.push({
          start: foundOccurrence,
          end: foundOccurrence + pattern.length - 1,
          badge: badge
        });
        foundOccurrence += 1;
      }
      startOffset = foundOccurrence;
    }
    return tags;
  }

  recordQuerySelected = (query) => {
    // NEXT: Clear selection when object or record changes.
    this.activeRecordQuery = query;
    this.parent.forceUpdate();
  }

  expandedRowRender = (row) => {
    var text = row.passage.text.split("@highlight").join("<br/> →   ");

    var tags = [];
    var tagsIndex = {};
    for (var entity of row.passage.entities) {
      var tag = {
        start: entity.start,
        end: entity.end,
        badge: "info"
      };
      tags.push(tag);

      tagsIndex[tag.start + "-" + tag.end] = tag;
    }
    tags = tags.sort(function(a, b) { return a.start - b.start; });

    var queries = [];
    for (let query of row.qas) {
      var queryTags = this.findMatchingTags(query.query, "@placeholder", "secondary");
      queries.push(
        <ListGroupItem
          onClick={(e) => this.recordQuerySelected(query)}
          className={query === this.activeRecordQuery ? "record-selected-query" : ""}>

          <TaggerView
            content={query.query}
            tags={queryTags}
          />
        </ListGroupItem>
      );

      if ( query === this.activeRecordQuery ) {
        if (query.answers) {
          for (var answer of query.answers) {
            try {
              var answerTag = tagsIndex[answer.start + "-" + answer.end];
              answerTag.badge = "success";
            } catch(err) {
              // This indicates dataset inconsistency when answer does not point at an entity from the passage.
            }
          }
        }
      }
    }

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Text>
              <strong>Passage Text</strong>
            </Card.Text>
            <Card.Text>
              <Card>
                <Card.Body>
                  <TaggerView
                    content={text}
                    tags={tags}
                  />
                </Card.Body>
              </Card>
            </Card.Text>
            <Card.Text>
              <strong>Queries</strong> &nbsp;
              <InfoTooltip
                text="Click on a query to see answers highlighted."/>
            </Card.Text>
            <Card.Text>
              <Card>
                <ListGroup className="list-group-flush">
                  {queries}
                </ListGroup>
              </Card>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
